import React from 'react'
import Thailand1 from "../imagesmy/Thailand-1.jpg";
import Thailand2 from "../imagesmy/Thailand-2.jpg";
import Thailand3 from "../imagesmy/Thailand-3.jpg";
import Thailand4 from "../imagesmy/Thailand-4.jpg";
import Thailand5 from "../imagesmy/Thailand-5.jpg";
import Thailand6 from "../imagesmy/Thailand-6.jpg";
import Thailand7 from "../imagesmy/Thailand-7.png";
import Footer from "./Footer";
import { Topnav } from "./Topnav";
import Navbar from "./Navbar";
import { Container, Grid, Paper, Typography } from "@mui/material";

const BlogThailand = () => {
    return (
        <>
            <Topnav />
            <Navbar />

            <Container maxWidth="lg" style={{ marginTop: "20px" }}>
                <Grid container spacing={3} justifyContent="center">
                    <Grid item xs={12}>
                        <Paper style={{ padding: "20px" }} elevation={3}>
                            <img
                                src={Thailand5}
                                alt="Blog Cover"
                                style={{ width: "100%", height: "500px " }}
                            />
                            <Typography variant="h4" style={{ marginTop: "20px" }}>

                                Which Thai island is right for you?
                            </Typography>
                            <Typography variant="subtitle1" color="textSecondary">
                                Published on: OCt 26, 2023
                            </Typography>
                            <Typography variant="body1" style={{ marginTop: "20px" }}>
                                {/* Your blog content goes here */}
                                We've travelled to each island to help you understand the vibe, pace and beaches on offer. Here's our guide to these postcard-pretty isles.
                            </Typography>

                            {/* Vilamendhoo Island Resort */}
                            <Typography variant="h6" style={{ marginTop: "20px" }}>
                                Koh Samui Best for: the good all-rounder
                            </Typography>
                            <img
                                src={Thailand1}
                                alt="Vilamendhoo Island Resort"
                                style={{ width: "100%", height: "auto" }}
                            />
                            <Typography variant="body1" style={{ marginTop: "10px" }}>
                                Koh Samui is perfect for first timers to Thailand. There are many things to do here, from shopping at Fisherman's Village market to a cultural trip to the famous Big Buddha landmark. Powder-soft sand beaches, candle-lit meals and an easy-going atmosphere await. If you're looking for a honeymoon or a romantic escape, we recommend the recently refreshed Rocky's Boutique Resort, exclusive to Kuoni in the UK. This charming and remote couples' retreat offers Thai and French cuisine right by the shore, massages in the beachfront sala and the warmest of welcomes, making you feel like family.
                            </Typography>

                            {/* Sandies Bathala */}
                            <Typography variant="h6" style={{ marginTop: "20px" }}>
                                Koh Yao Noi Best for: untouched island relaxation
                            </Typography>
                            <img
                                src={Thailand2}
                                alt="Sandies Bathala"
                                style={{ width: "100%", height: "auto" }}
                            />
                            <Typography variant="body1" style={{ marginTop: "10px" }}>
                                Unspoilt Koh Yao Noi is the go-to island if you want to experience old Thailand before the big hotels move in. Reaching this small, intimate isle is easy – you can take a speedboat ride from neighbouring Phuket or Krabi and be there in around 30 minutes. It's a fabulous place for pure escapism with isolated bays and rustic restaurants. Watch friendly locals fish in the clear waters, sip ice-cold cocktails under towering palms and discover the secluded coast on a kayaking adventure. One of our favourite places to stay here is the laid-back Cape Kudu Hotel. It's a stylish five-star boutique hideaway with a salt-water infinity pool and enchanting moonlight cinema nights. The grey and white colour scheme and beach-chic vibe give it a cool, contemporary look. You can wake up in a Deluxe Room or Pool Villa and wind down in the evening by tucking into authentic Thai dishes at Hornbill Restaurant; don't miss the crispy banana fritters for dessert.
                            </Typography>

                            {/* All-Inclusive Escape Section */}
                            {/* Add similar sections for Adaaran Select Hudhuranfushi and Emerald Maldives */}
                            {/* Adaaran Select Hudhuranfushi */}
                            <Typography variant="h6" style={{ marginTop: "20px" }}>
                                Koh Lanta Best for: a holiday away from the crowds
                            </Typography>
                            <img
                                src={Thailand3}
                                alt="Sandies Bathala"
                                style={{ width: "100%", height: "auto" }}
                            />
                            <Typography variant="body1" style={{ marginTop: "10px" }}>
                                Koh Lanta's carefree, bohemian attitude gives the isle real character. As one of the less-populated areas in Thailand, this is a great spot for a serene stay. The two islands of Koh Lanta Yai (big) and Koh Lanta Noi (small) are connected by a bridge and are reached by car and ferry or boat ride from Krabi. Koh Lanta's calm waters are home to colourful marine life and there are no motorised watersports here, so you are guaranteed a refreshingly peaceful beach experience. You'll find the quaint Layana Resort & Spa on the blonde sands of a striking mile-long beach. Watch mesmerising orange sunsets as you taste zesty fruit shakes by the ocean or indulge in an exotic coconut scrub treatment at the spa.
                            </Typography>

                            {/* Adaaran Select Hudhuranfushi */}
                            <Typography variant="h6" style={{ marginTop: "20px" }}>
                                Phuket Best for: day trips and luxury stays
                            </Typography>
                            <img
                                src={Thailand4}
                                alt="Adaaran Select Hudhuranfushi"
                                style={{ width: "100%", height: "auto" }}
                            />
                            <Typography variant="body1" style={{ marginTop: "10px" }}>
                                Phuket is big on entertainment. Notorious Patong is undoubtedly brash, and its exotic bars, nightclubs and neon-lit streets make it a famous place for parties. But we know the best hotels that are just a short walk or a tuk-tuk ride away, and some of these are set on almost-deserted beaches, so you can relax at a distance but be close enough to go and see what all the fuss is about. Kata is a lively alternative with excellent bars, restaurants, and surfing spots. And a holiday in Phuket wouldn't be complete without a trip to 'James Bond Island'. We've scoured the island and have found some mind-blowing resorts to share with you. Such as The Shore at Katathani, where every villa has a private pool and you're just steps from idyllic Kata Noi beach. It's the ultimate place to celebrate your honeymoon or anniversary, as not only are the rooms the perfect little love nests, Kuoni travellers will receive an exclusive romantic dinner on us.
                            </Typography>

                            {/* Emerald Maldives */}
                            <Typography variant="h6" style={{ marginTop: "20px" }}>
                                Koh Phangan Best for: away from it all relaxation
                            </Typography>
                            <img
                                src={Thailand5}
                                alt="Emerald Maldives"
                                style={{ width: "100%", height: "auto" }}
                            />
                            <Typography variant="body1" style={{ marginTop: "10px" }}>
                                Koh Phangan is well-known for its full moon party scene, but we know another side to the island where you can flee the non-stop nature of Haad Rin and take some time out with a laid-back getaway. The island is a 30-minute boat ride north of Koh Samui, so it's easy to combine islands, and the clear, deep waters are ideal for diving. On land, set off in search of Nam Tok Phaeng waterfall or head to Thong Sala night market for tasty seafood and spicy noodles. We suggest a stay at the stunning Anantara Rasananda Koh Phangan Villas, sitting on the quiet northeast coast. Thick emerald jungle frames this barefoot-luxe resort, which has an amazing sea view infinity pool right on the beach.
                            </Typography>

                            {/* Active Family Holiday Section */}
                            {/* Add similar sections for Kuramathi Maldives and Dhigali Maldives */}
                            {/* Kuramathi Maldives */}
                            <Typography variant="h6" style={{ marginTop: "20px" }}>
                                Koh Phi Phi Best for: beach parties and a lively vibe
                            </Typography>
                            <img
                                src={Thailand6}
                                alt="Sandies Bathala"
                                style={{ width: "100%", height: "auto" }}
                            />
                            <Typography variant="body1" style={{ marginTop: "10px" }}>
                                You can't help but be starstruck by Koh Phi Phi's sapphire shores and sugary sands. Koh Phi Phi Don is famous for its energetic aura, but when relaxation calls, you can find gorgeous little coves that ooze peace and tranquillity in Phi Phi Leh. Here you can explore Maya Bay, the filming location of The Beach, where towering limestone rocks border the blue and rustic canoes float along the shore. Spend an afternoon wandering around the island's craft shops before stopping for an ice-cold drink at one of the hip bars, or take the 30-minute climb up to the viewpoint. It does involve a steep hike up winding steps, but the view at the top is so worth it. Stay at the beautifully secluded Saii Phi Phi Island Village; we love the ocean-view Hillside Pool Villas dotted along the slopes of the rainforest.


                            </Typography>

                            {/* Kuramathi Maldives */}
                            <Typography variant="h6" style={{ marginTop: "20px" }}>
                                Koh Samet Best for: an authentic taste of real Thailand
                            </Typography>
                            <img
                                src={Thailand7}
                                alt="Kuramathi Maldives"
                                style={{ width: "100%", height: "auto" }}
                            />
                            <Typography variant="body1" style={{ marginTop: "10px" }}>
                                Koh Samet is one of the closest islands to Thailand's vibrant capital, Bangkok. You don't need to fly there like most other Thai islands – a three-hour car journey from the city and a 45-minute speedboat ride will take you to this understated paradise, where you'll find a taste of traditional Thailand thanks to its friendly locals and rustic feel. Koh Samet is classified as a national park, so you'll be spoilt with woodlands and exciting wildlife, from sea turtles to flat-head geckos. Romantic Paradee Resort is a five-star all-villa retreat, ideal if you're looking for a chilled escape with your other half or a friend. During your stay, book a private dinner on the beach. Listening to the waves tumbling at the shore as you tuck into delicious Asian dishes under a blanket of stars is an experience you might find hard to beat.
                            </Typography>

                            {/* Dhigali Maldives */}

                            {/* Escape and Relax Section */}
                            {/* Add similar sections for Kagi Maldives Spa Island and Komandoo Island Resort & Spa */}

                        </Paper>
                    </Grid>
                </Grid>
            </Container>

            <Footer />
        </>
    )
}

export default BlogThailand