import React from 'react'
import { Accordion, Container, Grid, Paper } from '@mui/material';
import giltFliht from '../imagesmy/girlflight.png';
import phonewhite from '../imagesmy/phonewhite.svg';
import Button from '@mui/material/Button';
import './Cards.css';
const customButtonStyle = {
    background: '#FC3C00',
    color: 'white', 
  };

const BookSection = () => {
    return (
        <div>



            <section className='bg-myblue'>
                <Container maxWidth="lg">

                    <Grid container spacing={2}  alignItems="center">
                        <Grid item xs={12} sm={6}>
                            <div>

                                <h2 className='mb-0 text-'>DISCLAIMER</h2>
                                <p className='my-10'>Bliss Flights LTD is an independent online travel service provider that provides cost-effective travel services. We do not claim that we are Airlines. Brands displayed on our pages are owned by the respective brand owners. In case if you find any content on our website which violates your privacy or any copyright laws then report us on info@blisflights.co.uk or call us 0203 831 3448.</p>
                            </div>

                        </Grid>
                      
                    </Grid>


                </Container>
            </section>
            <section className='bg-myblue blue-section'>
                <Container maxWidth="lg">

                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={6}>
                            <div className='text-white'>

                                <h2 className='mb-0'>Get Your Tailor made holiday quote</h2>
                                <p className='my-10'>We're here to tailor-make it easy...</p>
                                <Button className='rounded-8' variant="contained" style={customButtonStyle}> <img className='me-10' src={phonewhite} alt="" /> 020 3927 7690</Button>
                            </div>

                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <img className='flight-banner d-lg-block d-none' src={giltFliht} alt="" />

                        </Grid>
                    </Grid>


                </Container>
            </section>





        </div>
    )
}

export default BookSection