import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import logo from '../imagesmy/footerlogo.svg';
import phonered from '../imagesmy/phonered.svg'
import mailred from '../imagesmy/mailred.svg'
import facebookIcon from '../imagesmy/facebook.svg'
import insta from '../imagesmy/insta.svg'
import Linkein from '../imagesmy/in.svg'
import twitter from '../imagesmy/twitter.svg'
import paymentmethod from '../imagesmy/paymentmethod.svg'


function Footer() {
	return (
		<>
			{/* <div className='bottom-bar-top'>
		<div className='bottom-left'>
		
        <div className='img'>
         <img src='images/bottom-bar-left.png'/>
		</div>
		
		<div className='left-content'>
			<h2>Get Your Tailor made holiday quote</h2>
			<p>We're here to tailor-make it easy...</p>
		</div>
		 
		</div>
		<div className='bottom-right'>
        <div className='img'>
         <img src='images/bottom-bar-right.png'/>
		</div>
		<div className='left-content'> 
			
			<p>For Booking & Latest Offers</p>
			<p>Call Our Experts Now</p>
			<div className='phone'>
			<h2><a href="tel:020-3927-7690"> 020 3927 7690</a></h2>
			</div>
		</div>
		</div>
	
	</div> */}

			<footer className="footer">

				<div className="container2">

					<div className="row">
						<div className="footer-col">
							{/* <img src='logo192.png' className='f-logo'/> */}
							<div>
								<img src={logo} alt="footerlogo" />
							</div>
							<p>Welcome to the world of Blessings where all your travel dreams come true!
								Do you always dream about perfect holiday? But you are confused where to get the best cheap deals for your holidays
								or business trips? Then Bliss Flights is the right choice for you!
								If you wish to travel far and fast like travel light, pack your entire wish and take off all your troubles.</p>

						</div>
						<div className="footer-col">
							<h4>Get Help</h4>

							<div className='icon-flex'>
								<img className='myicon' src={phonered} alt="phonered" />
								<a href="tel:020-3927-7690">  020 3927 7690</a>
							</div>


							<div className='icon-flex'>
								<img className='myicon' src={mailred} alt="mailred" />

								<a href='mailto:info@bliss-flights.co.uk' >info@bliss-flights.co.uk</a>
							</div>


						</div>
						<div className="footer-col">
							<h4>Menus</h4>
							<ul>
								<li><Link to='/'>Home</Link></li>
								<li><Link to='/about-us'>About_Us</Link></li>
								<li><Link to='/contact-us'>Contact_Us</Link></li>
								<li><Link to='/terms_conditions'>Terms & Conditions</Link> </li>
							</ul>
						</div>
						<div className="footer-col">
							<h4>follow us</h4>
							<div className="social-links">
								<a href="#"><i><img src={facebookIcon} alt="" /></i></a>
								<a href="#"><i><img src={twitter} alt="" /></i></a>
								<a href="#"><i><img src={insta} alt="" /></i></a>
								<a href="/"><i><img src={Linkein} alt="" /></i></a>

							</div>
							
						</div>
					</div>
				</div>
				<hr />
				<div className='card-icons copyright-wrapper'>
				<p className='copyright'>Bliss Flight © 2022 - All Rights Reserved |<Link className='privacy_link' to='/privacy_policy'> Privacy Policy</Link> </p>
				<img src={paymentmethod} alt="card icons" width="250" height="50" />

							</div>
			</footer>
		</>
	);
}

export default Footer;
