import React, { useState, useRef, useEffect } from 'react';
import { Box, TextField, Button, Modal, Typography, Checkbox, InputLabel, Select, TextareaAutosize, Grid, Paper, Container } from "@material-ui/core";
import "./bookflight.css"
import Footer from '../Footer'
import Navbar from '../Navbar'
import Clock from '../../imagesmy/clock.svg'
import CallNow from '../../imagesmy/callNow.svg'
import AirPlane from '../../imagesmy/airplane.svg'
import DotedLine from '../../imagesmy/dotedLine.svg'
import YourBooking from '../../imagesmy/your-booking.svg'
import Location from '../../imagesmy/location.svg'
import Taxes from '../../imagesmy/taxes.svg'
import calendar from '../../imagesmy/calendar.svg'
import BestPrice from '../../imagesmy/best-price.svg'
import TopQuality from '../../imagesmy/top-quality.svg'
import Over150 from '../../imagesmy/over-150.svg'
import NoExtra from '../../imagesmy/no-extra.svg'
import PassengersDetails from '../../imagesmy/PassengersDetails.svg'
import Payment from '../../imagesmy/Payment.svg'
import Confirmation from '../../imagesmy/Confirmation.svg'
import FirstBar from '../../imagesmy/firstBar.svg'
import WindowCorridor from '../../imagesmy/window-corridor.svg'
import Hannover from '../../imagesmy/hannover.svg'
import Refund from '../../imagesmy/refund.svg'
import Sickness from '../../imagesmy/Sickness.svg'
import Private from '../../imagesmy/Private.svg'
import HomeEmergency from '../../imagesmy/HomeEmergency.svg'
import PreExisting from '../../imagesmy/Pre-existing.svg'
import Public from '../../imagesmy/Public-Transport.svg'
import EmergencyServices from '../../imagesmy/Emergency-services.svg'
import AndMany from '../../imagesmy/And-many.svg'
import NotAvailable from '../../imagesmy/notavailable.svg'
import Available from '../../imagesmy/Available.svg'
import MorefootSpace from '../../imagesmy/More foot.svg'
import CheckWhite from '../../imagesmy/check.svg'
import EmptyBar from '../../imagesmy/emptybar.svg'
import emailjs from '@emailjs/browser';
import LinearProgress from '@mui/material/LinearProgress';


import Tooltip from '@mui/material/Tooltip';
import { useLocation } from 'react-router-dom';
import AllData from '../../dashboard/data/AllData';
import BookSection from '../BookSection';
import CustomCheckbox from '../CustomCheckbox';
function CheckOut() {

  const [moreFootSpaceChecked, setMoreFootSpaceChecked] = React.useState(true);
  const [blueCheckboxChecked, setBlueCheckboxChecked] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [selectedSeats, setSelectedSeats] = useState("");
  const handleClosepop = () => setOpen(false);
  const colors = ['white', 'bg-cross', 'bg-orange'];
  const { state } = useLocation();

  const { flightData, timeFrom, timeTo, retunrtimefrom, retunrtimeto } = state;

  const [moreFootSpaceChecked1, setMoreFootSpaceChecked1] = React.useState(false);
  const [moreFootSpaceChecked2, setMoreFootSpaceChecked2] = React.useState(false);
  const [moreFootSpaceChecked3, setMoreFootSpaceChecked3] = React.useState(false);
  const [moreFootSpaceChecked4, setMoreFootSpaceChecked4] = React.useState(false);
  const [moreFootSpaceChecked5, setMoreFootSpaceChecked5] = React.useState(false);
  const [moreFootSpaceChecked6, setMoreFootSpaceChecked6] = React.useState(false);

  // State variables for blue checkboxes
  const [blueCheckboxChecked1, setBlueCheckboxChecked1] = React.useState(false);
  const [blueCheckboxChecked2, setBlueCheckboxChecked2] = React.useState(false);
  const [blueCheckboxChecked3, setBlueCheckboxChecked3] = React.useState(false);
  const [blueCheckboxChecked4, setBlueCheckboxChecked4] = React.useState(false);
  const [blueCheckboxChecked5, setBlueCheckboxChecked5] = React.useState(false);
  const [blueCheckboxChecked6, setBlueCheckboxChecked6] = React.useState(false);
  const [blueCheckboxChecked7, setBlueCheckboxChecked7] = React.useState(false);
  const [blueCheckboxChecked8, setBlueCheckboxChecked8] = React.useState(false);
  const [blueCheckboxChecked9, setBlueCheckboxChecked9] = React.useState(false);
  const [blueCheckboxChecked10, setBlueCheckboxChecked10] = React.useState(false);
  const [blueCheckboxChecked11, setBlueCheckboxChecked11] = React.useState(false);
  const [blueCheckboxChecked12, setBlueCheckboxChecked12] = React.useState(false);
  const [blueCheckboxChecked13, setBlueCheckboxChecked13] = React.useState(false);
  const [blueCheckboxChecked14, setBlueCheckboxChecked14] = React.useState(false);


  const [blueCheckboxChecked15, setBlueCheckboxChecked15] = React.useState(false);
  const [blueCheckboxChecked16, setBlueCheckboxChecked16] = React.useState(false);
  const [blueCheckboxChecked17, setBlueCheckboxChecked17] = React.useState(false);
  const [blueCheckboxChecked18, setBlueCheckboxChecked18] = React.useState(false);
  const [blueCheckboxChecked19, setBlueCheckboxChecked19] = React.useState(false);
  const [blueCheckboxChecked20, setBlueCheckboxChecked20] = React.useState(false);
  const [blueCheckboxChecked21, setBlueCheckboxChecked21] = React.useState(false);
  const [blueCheckboxChecked22, setBlueCheckboxChecked22] = React.useState(false);
  const [blueCheckboxChecked23, setBlueCheckboxChecked23] = React.useState(false);
  const [blueCheckboxChecked24, setBlueCheckboxChecked24] = React.useState(false);



  // State variables for disabled checkboxes
  const [disabledCheckboxChecked1, setDisabledCheckboxChecked1] = React.useState(true);
  const [disabledCheckboxChecked2, setDisabledCheckboxChecked2] = React.useState(true);

  const handleMoreFootSpaceClick = (checkboxNumber) => {
    switch (checkboxNumber) {
      case 1:
        setMoreFootSpaceChecked1(!moreFootSpaceChecked1);
        break;
      case 2:
        setMoreFootSpaceChecked2(!moreFootSpaceChecked2);
        break;
      case 3:
        setMoreFootSpaceChecked3(!moreFootSpaceChecked3);
        break;
      case 4:
        setMoreFootSpaceChecked4(!moreFootSpaceChecked4);
        break;
      case 5:
        setMoreFootSpaceChecked5(!moreFootSpaceChecked5);
        break;
      case 6:
        setMoreFootSpaceChecked6(!moreFootSpaceChecked6);
        break;
      default:
        break;
    }
  };

  const handleBlueCheckboxClick = (checkboxNumber) => {
    switch (checkboxNumber) {
      case 1:
        setBlueCheckboxChecked1(!blueCheckboxChecked1);
        break;
      case 2:
        setBlueCheckboxChecked2(!blueCheckboxChecked2);
        break;
      case 3:
        setBlueCheckboxChecked3(!blueCheckboxChecked3);
        break;
      case 4:
        setBlueCheckboxChecked4(!blueCheckboxChecked4);
        break;
      case 5:
        setBlueCheckboxChecked5(!blueCheckboxChecked5);
        break;
      case 6:
        setBlueCheckboxChecked6(!blueCheckboxChecked6);
        break;
      case 7:
        setBlueCheckboxChecked7(!blueCheckboxChecked7);
        break;
      case 8:
        setBlueCheckboxChecked8(!blueCheckboxChecked8);
        break;
      case 9:
        setBlueCheckboxChecked9(!blueCheckboxChecked9);
        break;
      case 10:
        setBlueCheckboxChecked10(!blueCheckboxChecked10);
        break;
      case 11:
        setBlueCheckboxChecked11(!blueCheckboxChecked11);
        break;
      case 12:
        setBlueCheckboxChecked12(!blueCheckboxChecked12);
        break;
      case 13:
        setBlueCheckboxChecked13(!blueCheckboxChecked13);
        break;
      case 14:
        setBlueCheckboxChecked14(!blueCheckboxChecked14);
        break;
      case 15:
        setBlueCheckboxChecked15(!blueCheckboxChecked15);
        break;
      case 16:
        setBlueCheckboxChecked16(!blueCheckboxChecked16);
        break;
      case 17:
        setBlueCheckboxChecked17(!blueCheckboxChecked17);
        break;
      case 18:
        setBlueCheckboxChecked18(!blueCheckboxChecked18);
        break;
      case 19:
        setBlueCheckboxChecked19(!blueCheckboxChecked19);
        break;
      case 20:
        setBlueCheckboxChecked20(!blueCheckboxChecked20);
        break;
      case 21:
        setBlueCheckboxChecked21(!blueCheckboxChecked21);
        break;
      case 22:
        setBlueCheckboxChecked22(!blueCheckboxChecked22);
        break;
      case 23:
        setBlueCheckboxChecked23(!blueCheckboxChecked23);
        break;
      case 24:
        setBlueCheckboxChecked24(!blueCheckboxChecked24);
        break;
      default:
        break;
    }
  };

  const handleDisabledCheckboxClick = () => {
    // No action for disabled checkbox
  };


  const columns = ['A', 'B', 'C', '', 'D', 'E', 'F'];
  const rows = Array.from({ length: 8 }, (_, index) => (index + 1).toString());

  const form = useRef();

  const [formData, setFormData] = useState({
    passengers: [
      {
        input1: '',
        input2: '',
        gender: 'male',
        dob: '',
        email: '',
        phone: '',
        message: '',
      },
    ],
  });

  const handleAddAnother = () => {
    setFormData({
      ...formData,
      passengers: [...formData.passengers, {
        input1: '',
        input2: '',
        gender: 'male',
        dob: '',
        email: '',
        phone: '',
        message: '',
      }],
    });
  };

  const handleInputChange = (e, passengerIndex) => {
    const { name, value } = e.target;
    const updatedPassengers = [...formData.passengers];
    updatedPassengers[passengerIndex][name] = value;
    setFormData({ ...formData, passengers: updatedPassengers });
  };

  // const handleAddAnother = () => {

  //   setFormData((prevState) => ({
  //     ...prevState,
  //     passengers: [
  //       ...prevState.passengers,
  //       {
  //         input1: '',
  //         input2: '',
  //         gender: 'male',
  //         dob: '',
  //       },
  //     ],
  //   }));
  // };
  const [ispriceVisible, setisPriceVisible] = useState(false);


  const togglePriceVisibility = () => {
    setisPriceVisible(true)
  }







  const style = {
    position: 'absolute',
    top: '20%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const [percentageValue, setPercentageValue] = useState(0);
  const [seasonaldata, setseasonalData] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(true);
    emailjs.sendForm('service_b78n6te', 'template_r0p8nyn', form.current, 'U9N4oeLD7PnGGl6QO')
      .then((result) => {
        window.location.reload();
      }, (error) => {
      });
  };
  const onclickHandler = (e) => {
    e.preventDefault();
    setOpen(true);

  };

  const firstWordOrigin = state?.flightData?.origin.split(' ')[0];
  const firstWordDesti = state?.flightData?.desti.split(' ')[0];
  const fstop = state?.flightData?.fstop.split(' ')[0];














  return (
    <>
      <Navbar />
      <Container maxWidth="lg" className='bookFlight-Main'>
        <div className='grid-container py-35'>

          <div className='column-9 '>

            <div className='grid-container my-40'>
              <div className="column-4 progresscol">
                <div>
                  <div className='d-flex'>
                    <img src={PassengersDetails} alt="" />
                    <p className='blue fw-700 progressBar'>Passengers Details</p>
                  </div>
                  <div>
                    <LinearProgress variant="determinate" value={50} className='mt-10 pBar' />
                  </div>
                  <div>
                    <p>Steps 1/2 </p>
                  </div>

                </div>
              </div>
              {/* <div className="column-4 progresscol ps-20">  <div>
                <div className='d-flex '>
                  <img src={Payment} alt="" />
                  <p className='fw-700 light-text progressBar '>Payment</p>
                </div>
                <div>
                  <LinearProgress variant="determinate" value={0} className='mt-10 pBar' />
                </div>
                <div>
                  <p>Steps 2/3 </p>
                </div>

              </div></div> */}
              <div className="column-4 progresscol ps-20">  <div>
                <div className='d-flex'>
                  <img src={Confirmation} alt="" />
                  <p className='fw-700 light-text progressBar'>Confirmation</p>
                </div>
                <div>
                  <LinearProgress variant="determinate" value={0} className='mt-10 pBar' />
                </div>
                <div>
                  <p>Steps 2/2 </p>
                </div>

              </div></div>
            </div>

            <div className='Card-Main-Container grid-container-flight  flight-card-bg mb-20 p-0'>

              <div className=' Card-Left-Container'>

                <div className='Left-Top'>



                </div>
                <div className='logo-container'>
                  <img className="cellImg text-center flight-logo" src={state?.flightData?.img} height={30} width={50} alt="avatar" />
                  <h5 className='mb-12'>{state?.flightData?.airline}</h5>
                  <span className='btn btnGreen text-white'>{state?.flightData?.classes}</span>
                </div>
                <div className='Left-Time mt-30'>
                  <img src={Clock} alt="" />
                  <p className='fs-14 fw-700'>Total Flight Duration</p>

                </div>
                <div className='Left-Time-Time'>

                  <h5 className='fs-18 fw-800'>{state?.flightData?.fduration}</h5>
                </div>
              </div>
              <div className='Card-Center-Container'>



                <div className='From-Stop-To-Caontainer'>
                  <div className='Center-Departure'>
                  <h3 className='fw-800 fs-28'>{timeFrom}</h3>
                    <Tooltip title={
                      <div>
                        <img src={AirPlane} alt="Your Image" width="100" height="100" />
                        <p>abc</p>
                      </div>
                    } arrow>
                      <p className='pText' style={{ textTransform: "uppercase" }}>{firstWordOrigin.endsWith(',') ? firstWordOrigin.slice(0, -1) : firstWordOrigin}</p>
                    </Tooltip>
                  </div>
                  <div className='airplane-line'>
                    {/* <p className='text-center fw-700'>{state?.flightData?.fduration}</p> */}

                    <div class=" position-relative text-center">
                     
                     
                      
                      <img src={DotedLine} alt="" className='dotted-line' />

                    </div>
                    <div className=' d-flex justify-content-center mr-73'>
                      {/* <p className='Ocolor '>
                        1 Stop  </p> */}
                      {/* <p className='pText text-center'><Tooltip title={
                          <div>
                            <img src={AirPlane} alt="Your Image" width="100" height="100" />
                            <p>doh</p>
                          </div>
                        } arrow>
                          <p className='pText' style={{ textTransform: "uppercase" }}>{fstop.endsWith(',') ? fstop.slice(0, -1) : fstop}</p>
                        </Tooltip></p> */}

                    </div>
                    <div className='Center-Stop2 position-relative'>
                      {/* <h5>{doc.fstop}</h5> */}

                    </div>
                  </div>
                  <div className='Center-Travel-To '>
                  <h3 className='fw-800 fs-28'>{timeTo}</h3>
                    <Tooltip title={
                      <div>
                        <img src='' alt="Your Image" width="100" height="100" />
                        <p>lon</p>
                      </div>
                    } arrow>
                      <p className='pText' style={{ textTransform: "uppercase" }}>{firstWordDesti.endsWith(',') ? firstWordDesti.slice(0, -1) : firstWordDesti}</p>
                    </Tooltip>
                  </div>

                </div>

                <div className='From-Stop-To-Caontainer mt-100'>
                  <div className='Center-Departure'>
                  <h3 className='fw-800 fs-28'>{retunrtimefrom}</h3>
                    <Tooltip title={
                      <div>
                        <img src='' alt="Your Image" width="100" height="100" />
                        <p>lon</p>
                      </div>
                    } arrow>
                      <p className='pText' style={{ textTransform: "uppercase" }}>{firstWordDesti.endsWith(',') ? firstWordDesti.slice(0, -1) : firstWordDesti}</p>
                    </Tooltip>
                  </div>

                  <div className='airplane-line'>
                    {/* <p className='text-center fw-700'>{state?.flightData?.fduration}</p> */}
                    <div class=" position-relative text-center">
                      <img src={DotedLine} alt="" className='dotted-line' />

                    </div>

                    <div className=' d-flex justify-content-center mr-73'>
                      {/* <p className='Ocolor  mr-22'>
                        1 Stop  </p> */}

                      {/* <p className='pText text-center'><Tooltip title={
                          <div>
                            <img src='' alt="Your Image" width="100" height="100" />
                            <p>doh</p>
                          </div>
                        } arrow>
                          <p className='pText' style={{ textTransform: "uppercase" }}>{fstop.endsWith(',') ? fstop.slice(0, -1) : fstop}</p>
                        </Tooltip></p> */}

                    </div>

                    {/* <div className='return-return'>
                          
                        <h5>Return Date: {state.Todate.toDateString()}</h5>
                      </div> */}
                    <div className='Center-Stop2'>
                      {/* <h5>{doc.fstop}</h5> */}
                    </div>
                  </div>

                  <div className='Center-Travel-To '>
                  <h3 className='fw-800 fs-28'>{retunrtimeto}</h3>
                    <Tooltip title={
                      <div>
                        <img src={AirPlane} alt="Your Image" width="100" height="100" />
                        <p>lag</p>
                      </div>
                    } arrow>
                      <p className='pText' style={{ textTransform: "uppercase" }}>{firstWordOrigin.endsWith(',') ? firstWordOrigin.slice(0, -1) : firstWordOrigin}</p>
                    </Tooltip>
                  </div>
                </div>
                {/* <div className='Center-Bottom-Bar'>
                        <h4 className='flightClass'>Flight Class:</h4>
                        <h4 className='flightClassB'>{doc.classes}</h4>
                                              </div> */}

              </div>
              <div className=' Card-Right-Container'>
                <div className='d-flex justify-content-center tag-wraaper'>
                  <div className='adultBg p-10'>
                    {state?.adultPrice > 0 && (
                      <div className='adultBg'>
                        <Tooltip title={
                          <div>
                            ₤{state?.adultPrice}
                          </div>
                        } arrow>
                          <h4 className='fs-14'>Adult</h4>
                        </Tooltip>
                      </div>
                    )}
                  </div>




                  <div>
                    {state?.childPrice > 0 && (
                      <div className='adultBg p-10'>
                        <Tooltip title={
                          <div>
                            ₤{state?.childPrice}
                          </div>
                        } arrow>
                          <h4 className='fs-14'>Child</h4>
                        </Tooltip>
                      </div>
                    )}
                  </div>

                  <div>
                    {state?.infantPrice > 0 && (
                      <div className='adultBg p-10'>
                        <Tooltip title={
                          <div>
                            ₤{state?.infantPrice}
                          </div>
                        } arrow>
                          <h4 className='fs-14'>Infant</h4>
                        </Tooltip>
                      </div>
                    )}
                  </div>


                </div>


                <div className='Right-Fare-Total d-flex text-center justify-content-center Ocolor fs-30 mt-20 fw-800'>
                  <h4>₤</h4>

                  <h3 className='ms-10'>{state?.totalPrice}</h3>
                </div>


                {/* <Button className='btn subBtn fs-18 fw-700 p-15-10 mt-15'  >Book Now
                <img src={BookNow} alt="" className='ms-10' />
              </Button> */}
                <div className='btnCall d-flex align-items-center pl-10 pr-10 mt-20'>
                  <div>
                    <img src={CallNow} alt="" />
                  </div>
                  <div className=' pl-23'>
                    <p className='fs-12 fw-700 mb-0'>Call Now</p>
                    <a className='fnumber btn text-black text-center fs-18 fw-700' href="tel:020-3927-7690">

                      020 3927 7690</a>
                  </div>

                </div>
              </div>


            </div>

            <div>
              {formData.passengers.map((passenger, index) => (
                <div className='mt-30 flight-card-bg' key={index}>
                  <div className='py-15 border-bottom'>
                    <div className='d-flex px-10'>

                      <p className='one-bg text-center'>{index + 1}</p>
                      <h3 className='ml-8'>
                        Passengers Details
                      </h3>
                    </div>
                  </div>
                  <div className='p-20'>
                    <form onSubmit={handleSubmit} ref={form}>
                      <div className='grid-container'>
                        <div className='column-5 firstNamecol'>
                          <InputLabel
                            htmlFor={`input1-${index}`}
                            className='text-black fw-800 fs-14 mb-5'
                          >
                            First Name
                          </InputLabel>
                          <TextField
                            variant='outlined'
                            name='first_name'
                            fullWidth
                            className='myInputs mb-10'
                            placeholder='Type Name'
                            required
                          />
                        </div>
                        <div className='column-5 firstNamecol'>
                          <InputLabel
                            htmlFor={`input2-${index}`}
                            className='text-black fw-800 fs-14 mb-5'
                          >
                            Last Name
                          </InputLabel>
                          <TextField
                            variant='outlined'
                            name='last_name'
                            fullWidth
                            className='myInputs mb-10'
                            placeholder='Type Name'
                            required
                          />
                        </div>
                        <div className='column-2 firstNamecol'>
                          <InputLabel
                            htmlFor={`gender-${index}`}
                            className='text-black fw-800 fs-14 mb-5'
                          >
                            Gender
                          </InputLabel>
                          <Select classes="p-10 "
                            variant='outlined'
                            id={`gender-${index}`}
                            name='gender'
                            className='myInputs mb-10 w-100'
                            placeholder='Select'
                            fullWidth
                            defaultValue="male">
                            <option value='male' >Male</option>
                            <option value='female'>Female</option>
                            {/* <option value='other'>Other</option> */}
                          </Select>
                        </div>
                        <div className='column-5 firstNamecol'>
                          <InputLabel
                            htmlFor={`dob-${index}`}
                            className='text-black fw-800 fs-14 mb-5'
                          >
                            Date of Birth:
                          </InputLabel>
                          <TextField
                            variant='outlined'
                            type='date'
                            id={`dob-${index}`}
                            name='dob'
                            fullWidth
                            className='myInputs mb-10'
                            required
                          />
                        </div>
                      </div>

                      <div className='grid-container'>

                        <div className='mb-10 column-6 emilType'>
                          <label htmlFor="input4" className='text-black fw-800 fs-14 mb-5'>Email</label>
                          <input
                            fullWidth
                            variant="outlined"
                            name="user_email"
                            // value={formData.input4}
                            // onChange={handleInputChange}

                            className='myInputs mb-10 left0'
                            placeholder='Enter Email'
                            type='email'
                            required
                          />
                        </div>
                        <div className='mb-10 column-6'>
                          <label htmlFor="input5" className='text-black fw-800 fs-14 mb-5'>Phone Number</label> <br />
                          <input
                            variant="outlined"
                            name="user_phone"
                            // value={formData.input5}
                            // onChange={handleInputChange}
                            fullWidth
                            className='myInputs mb-10 w-100 p-phone'
                            placeholder='Enter Phone Number'
                            required
                            type='tel'
                          />
                        </div>
                      </div>

                      <input type="hidden" name="adult_price" value={state?.adultPrice} />
                      <input type="hidden" name="child_price" value={state?.childPrice} />
                      <input type="hidden" name="infant_price" value={state?.infantPrice} />
                      <input type="hidden" name="origin" value={state?.flightData?.origin} />
                      <input type="hidden" name="destination" value={state?.flightData?.desti} />
                      <input type="hidden" name="from" value={state?.fromDate} />
                      <input type="hidden" name="to" value={state?.toDate} />

                      <Button
                        type='submit'
                        variant="contained"
                        className='orangeBtn text-white p-15 fw-700'
                        fullWidth
                        size="large"  >
                        Submit
                      </Button>

                      {/* Add another goes here */}



                      {/* <div className='d-flex justify-content-center mt-30'>
                        // <Button onClick={handleAddAnother}
                         
                          className='addanotherbtn text-white fw-700 px-20 py-8'
                        >
                         
                          Add another
                        </Button>
                      </div> */}






                      <div className='mt-30 flight-card-bg p-15'>
                        <div>
                          <h3 className='fw-700'>
                            Select the places for your flights
                          </h3>
                        </div>
                        <div className='grid-container border-bottom py-27'>
                          <div className='column-9'>
                            <div className='select-flight-bg'>
                              <div className='d-flex p-15'>
                                <img src={WindowCorridor} alt="" />
                                <div>
                                  <h5 className='blue fw-700'>Window or corridor?</h5>
                                  <h6 className='fw-600 pText'>Book in advance so you won’t be disappointed later.</h6>

                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="column-3 best-option">
                            <div className='select-flight-bg py-20 px-10'>
                              <h3 className='fw-800 blue'>
                                £21.89
                              </h3>
                              <p>the best option for you</p>
                            </div>
                          </div>
                          <div className='column-6 position-relative'>
                            {/* <div>
                  <p className='mb-0 pText fw-600'>Flight #1</p>
                  <h4>Hannover (HAJ) - Milan (MIL)</h4>
                </div> */}
                            <div className='d-flex hannover-bg justify-content-between py-15 px-10 mt-10'>
                              <div className='d-flex mr-3'>

                                <img src={Hannover} alt="" />
                                <h4 className='ml-8 fw-700'>{state?.flightData?.origin} TO {state?.flightData?.desti}</h4>
                              </div>
                              <div>
                                <p className='mb-0 pText fw-600'>{state?.flightData?.airline}</p>
                              </div>
                            </div>
                            {/* <div className='d-flex hannover-bg justify-content-between py-15 px-10 mt-10'>
                  <div className='d-flex '>

                    <img src={Hannover} alt="" />
                    <h4 className='ml-8 fw-700'>Hannover (HAJ) - Milan (MIL)</h4>
                  </div>
                  <div>
                    <p className='mb-0 pText fw-600'>Flight #1</p>
                  </div>
                </div> */}
                            <div className='seat-icons'>
                              <div className='d-flex'>
                                <img src={NotAvailable} alt="" />
                                <p className='fw-700 ml-8'>Not Available</p>
                              </div>
                              <div className='d-flex mt-10'>
                                <img src={Available} alt="" />
                                <p className='fw-700 ml-8'>Available Place</p>
                              </div>
                              <div className='d-flex mt-10'>
                                <img src={MorefootSpace} alt="" />
                                <p className='fw-700 ml-8'>More foot Space</p>
                              </div>
                            </div>
                          </div>
                          <div className='column-6'>
                            <div className='seats-checkbox-bg'>
                              <div className="p-40 d-flex">





                                <div>
                                  <div class="grid-container">

                                    <Typography variant="subtitle1" className='fw-700 w-seats'>A</Typography>

                                    <Typography variant="subtitle1" className='fw-700 w-seats'>B</Typography>

                                    <Typography variant="subtitle1" className='fw-700 w-seats'>C</Typography>
                                  </div>




                                  <div class="grid-container">
                                    <div onClick={togglePriceVisibility}>
                                      <CustomCheckbox type="moreFootSpace" onClick={() => handleMoreFootSpaceClick(1)} checked={moreFootSpaceChecked1} />
                                    </div>
                                    <div onClick={togglePriceVisibility}>
                                      <CustomCheckbox type="moreFootSpace" onClick={() => handleMoreFootSpaceClick(2)} checked={moreFootSpaceChecked2} />
                                    </div>
                                    <div onClick={togglePriceVisibility}>
                                      <CustomCheckbox type="moreFootSpace" onClick={() => handleMoreFootSpaceClick(3)} checked={moreFootSpaceChecked3} />
                                    </div>
                                  </div>

                                  <div class="grid-container">
                                    <div onClick={togglePriceVisibility}>
                                      <CustomCheckbox type="blueCheckbox" onClick={() => handleBlueCheckboxClick(1)} checked={blueCheckboxChecked1} />
                                    </div>
                                    <div onClick={togglePriceVisibility}>
                                      <CustomCheckbox type="blueCheckbox" onClick={() => handleBlueCheckboxClick(2)} checked={blueCheckboxChecked2} />
                                    </div>
                                    <div onClick={togglePriceVisibility}>
                                      <CustomCheckbox type="disabledCheckbox" onClick={() => handleDisabledCheckboxClick(1)} checked={disabledCheckboxChecked1} disabled={true} />
                                    </div>
                                  </div>

                                  <div class="grid-container">
                                    <div onClick={togglePriceVisibility}>
                                      <CustomCheckbox type="blueCheckbox" onClick={() => handleBlueCheckboxClick(3)} checked={blueCheckboxChecked3} />
                                    </div>
                                    <div onClick={togglePriceVisibility}>
                                      <CustomCheckbox type="disabledCheckbox" onClick={() => handleDisabledCheckboxClick(1)} checked={disabledCheckboxChecked1} disabled={true} />
                                    </div>
                                    <div onClick={togglePriceVisibility}>
                                      <CustomCheckbox type="disabledCheckbox" onClick={() => handleDisabledCheckboxClick(1)} checked={disabledCheckboxChecked1} disabled={true} />
                                    </div>
                                  </div>

                                  <div class="grid-container">
                                    <div onClick={togglePriceVisibility}>
                                      <CustomCheckbox type="blueCheckbox" onClick={() => handleBlueCheckboxClick(4)} checked={blueCheckboxChecked4} />
                                    </div>
                                    <div onClick={togglePriceVisibility}>
                                      <CustomCheckbox type="disabledCheckbox" onClick={() => handleDisabledCheckboxClick(1)} checked={disabledCheckboxChecked1} disabled={true} />
                                    </div>
                                    <div onClick={togglePriceVisibility}>
                                      <CustomCheckbox type="disabledCheckbox" onClick={() => handleDisabledCheckboxClick(1)} checked={disabledCheckboxChecked1} disabled={true} />
                                    </div>
                                  </div>

                                  <div class="grid-container">
                                    <div onClick={togglePriceVisibility}>
                                      <CustomCheckbox type="blueCheckbox" onClick={() => handleBlueCheckboxClick(5)} checked={blueCheckboxChecked5} />
                                    </div>
                                    <div onClick={togglePriceVisibility}>
                                      <CustomCheckbox type="blueCheckbox" onClick={() => handleBlueCheckboxClick(6)} checked={blueCheckboxChecked6} />
                                    </div>
                                    <div onClick={togglePriceVisibility}>
                                      <CustomCheckbox type="disabledCheckbox" onClick={() => handleDisabledCheckboxClick(1)} checked={disabledCheckboxChecked1} disabled={true} />
                                    </div>
                                  </div>

                                  <div class="grid-container">
                                    <div onClick={togglePriceVisibility}>
                                      <CustomCheckbox type="blueCheckbox" onClick={() => handleBlueCheckboxClick(7)} checked={blueCheckboxChecked7} />
                                    </div>
                                    <div onClick={togglePriceVisibility}>
                                      <CustomCheckbox type="blueCheckbox" onClick={() => handleBlueCheckboxClick(8)} checked={blueCheckboxChecked8} />
                                    </div>
                                    <div onClick={togglePriceVisibility}>
                                      <CustomCheckbox type="blueCheckbox" onClick={() => handleBlueCheckboxClick(9)} checked={blueCheckboxChecked9} />
                                    </div>
                                  </div>

                                  <div class="grid-container">
                                    <div onClick={togglePriceVisibility}>
                                      <CustomCheckbox type="disabledCheckbox" onClick={() => handleDisabledCheckboxClick(1)} checked={disabledCheckboxChecked1} disabled={true} />
                                    </div>
                                    <div onClick={togglePriceVisibility}>
                                      <CustomCheckbox type="disabledCheckbox" onClick={() => handleDisabledCheckboxClick(1)} checked={disabledCheckboxChecked1} disabled={true} />
                                    </div>
                                    <div onClick={togglePriceVisibility}>
                                      <CustomCheckbox type="disabledCheckbox" onClick={() => handleDisabledCheckboxClick(1)} checked={disabledCheckboxChecked1} disabled={true} />
                                    </div>
                                  </div>


                                </div>




                                <div>
                                  <div class="grid-container">
                                    <Typography variant="subtitle1" className='fw-700 w-seats'>D</Typography>
                                    <Typography variant="subtitle1" className='fw-700 w-seats'>E</Typography>
                                    <Typography variant="subtitle1" className='fw-700 w-seats'>F</Typography>
                                  </div>

                                  <div class="grid-container">
                                    <div onClick={togglePriceVisibility}>
                                      <CustomCheckbox type="moreFootSpace" onClick={() => handleMoreFootSpaceClick(4)} checked={moreFootSpaceChecked4} />
                                    </div>
                                    <div onClick={togglePriceVisibility}>
                                      <CustomCheckbox type="moreFootSpace" onClick={() => handleMoreFootSpaceClick(5)} checked={moreFootSpaceChecked5} />
                                    </div>
                                    <div onClick={togglePriceVisibility}>
                                      <CustomCheckbox type="moreFootSpace" onClick={() => handleMoreFootSpaceClick(6)} checked={moreFootSpaceChecked6} />
                                    </div>
                                  </div>

                                  <div class="grid-container">
                                    <div onClick={togglePriceVisibility}>
                                      <CustomCheckbox type="blueCheckbox" onClick={() => handleBlueCheckboxClick(15)} checked={blueCheckboxChecked15} />
                                    </div>
                                    <div onClick={togglePriceVisibility}>
                                      <CustomCheckbox type="blueCheckbox" onClick={() => handleBlueCheckboxClick(16)} checked={blueCheckboxChecked16} />
                                    </div>
                                    <div onClick={togglePriceVisibility}>
                                      <CustomCheckbox type="disabledCheckbox" onClick={() => handleDisabledCheckboxClick(1)} checked={disabledCheckboxChecked1} disabled={true} />
                                    </div>
                                  </div>

                                  <div class="grid-container">
                                    <div onClick={togglePriceVisibility}>
                                      <CustomCheckbox type="disabledCheckbox" onClick={() => handleDisabledCheckboxClick(1)} checked={disabledCheckboxChecked1} disabled={true} />
                                    </div>
                                    <div onClick={togglePriceVisibility}>
                                      <CustomCheckbox type="blueCheckbox" onClick={() => handleBlueCheckboxClick(17)} checked={blueCheckboxChecked17} />
                                    </div>
                                    <div onClick={togglePriceVisibility}>
                                      <CustomCheckbox type="blueCheckbox" onClick={() => handleBlueCheckboxClick(18)} checked={blueCheckboxChecked18} />
                                    </div>
                                  </div>

                                  <div class="grid-container">
                                    <div onClick={togglePriceVisibility}>
                                      <CustomCheckbox type="disabledCheckbox" onClick={() => handleDisabledCheckboxClick(1)} checked={disabledCheckboxChecked1} disabled={true} />
                                    </div>
                                    <div onClick={togglePriceVisibility}>
                                      <CustomCheckbox type="disabledCheckbox" onClick={() => handleDisabledCheckboxClick(1)} checked={disabledCheckboxChecked1} disabled={true} />
                                    </div>
                                    <div onClick={togglePriceVisibility}>
                                      <CustomCheckbox type="disabledCheckbox" onClick={() => handleDisabledCheckboxClick(1)} checked={disabledCheckboxChecked1} disabled={true} />
                                    </div>
                                  </div>

                                  <div class="grid-container">
                                    <div onClick={togglePriceVisibility}>
                                      <CustomCheckbox type="blueCheckbox" onClick={() => handleBlueCheckboxClick(23)} checked={blueCheckboxChecked23} />
                                    </div>
                                    <div onClick={togglePriceVisibility}>
                                      <CustomCheckbox type="disabledCheckbox" onClick={() => handleDisabledCheckboxClick(1)} checked={disabledCheckboxChecked1} disabled={true} />
                                    </div>
                                    <div onClick={togglePriceVisibility}>
                                      <CustomCheckbox type="disabledCheckbox" onClick={() => handleDisabledCheckboxClick(1)} checked={disabledCheckboxChecked1} disabled={true} />
                                    </div>
                                  </div>

                                  <div class="grid-container">
                                    <div onClick={togglePriceVisibility}>
                                      <CustomCheckbox type="blueCheckbox" onClick={() => handleBlueCheckboxClick(24)} checked={blueCheckboxChecked24} />
                                    </div>
                                    <div onClick={togglePriceVisibility}>
                                      <CustomCheckbox type="disabledCheckbox" onClick={() => handleDisabledCheckboxClick(1)} checked={disabledCheckboxChecked1} disabled={true} />
                                    </div>
                                    <div onClick={togglePriceVisibility}>
                                      <CustomCheckbox type="blueCheckbox" onClick={() => handleBlueCheckboxClick(20)} checked={blueCheckboxChecked20} />
                                    </div>
                                  </div>

                                  <div class="grid-container">
                                    <div onClick={togglePriceVisibility}>
                                      <CustomCheckbox type="blueCheckbox" onClick={() => handleBlueCheckboxClick(19)} checked={blueCheckboxChecked19} />
                                    </div>
                                    <div onClick={togglePriceVisibility}>
                                      <CustomCheckbox type="blueCheckbox" onClick={() => handleBlueCheckboxClick(21)} checked={blueCheckboxChecked21} />
                                    </div>
                                    <div onClick={togglePriceVisibility}>
                                      <CustomCheckbox type="disabledCheckbox" onClick={() => handleDisabledCheckboxClick(1)} checked={disabledCheckboxChecked1} disabled={true} />
                                    </div>
                                  </div>


                                </div>















                              </div>


                            </div>
                          </div>
                        </div>
                        <div>
                          <div className=' confirmseat d-flex justify-content-between py-10'>
                            <div>
                              {ispriceVisible && <div>
                                <h3 className='fw-700'>£43.78</h3>
                                <p className='pText'>additional amount to the price</p>
                              </div>
                              }




                            </div>
                            <Button className='btn subBtn' type="submit">
                              <img src={CheckWhite} alt="" />
                              Confirm Seats
                            </Button>
                          </div>
                        </div>
                      </div>



                    </form>

                  </div>
                </div>
              ))}



            </div>
            {/* <div className='flight-card-bg py-20 px-20 mt-30 '>

              <form onSubmit={handleSubmit} >

                <div className='grid-container'>

                  <div className='mb-10 column-6 emilType'>
                    <label htmlFor="input4" className='text-black fw-800 fs-14 mb-5'>Email</label>
                    <input
                      fullWidth
                      variant="outlined"
                      name="user_email"
                      // value={formData.input4}
                      // onChange={handleInputChange}

                      className='myInputs mb-10 left0'
                      placeholder='Enter Email'
                      type='email'
                      required
                    />
                  </div>
                  <div className='mb-10 column-6'>
                    <label htmlFor="input5" className='text-black fw-800 fs-14 mb-5'>Phone Number</label> <br />
                    <input
                      variant="outlined"
                      name="user_phone"
                      // value={formData.input5}
                      // onChange={handleInputChange}
                      fullWidth
                      className='myInputs mb-10 w-100 p-phone'
                      placeholder='Enter Phone Number'
                      required
                      type='tel'
                    />
                  </div>
                </div>
                <div className='grid-container'>
                  <div className='column-12'>

                    <label htmlFor="input6" className='text-black fw-800 fs-14 mb-5'>Message</label>
                    <textarea
                      fullWidth
                      minRows={4}
                      variant="outlined"
                      maxRows={6}
                      placeholder="Message"
                      name="message"
                      // value={formData.message}
                      // onChange={handleInputChange}
                      required
                      className="no-resize w-100 myInputs p-10"
                    />
                  </div>

                </div>
                <Button
                  type="submit"
                  variant="contained"
                  className='orangeBtn text-white p-15 fw-700'
                  fullWidth
                  size="large"
                >
                  Submit
                </Button>
              </form>
            </div> */}

            <div className='mt-30 flight-card-bg'>

              <div className='column-12'>
                <div className=''>
                  <div className='d-flex px-20 py-20 border-bottom'>
                    <img src={Refund} alt="" />
                    <h2 className='mb-0 ml-8'>Refund Terms</h2>
                  </div>
                  <div className='grid-container px-20 py-20'>
                    <div className='column-5 sikness  '>
                      <div className='d-flex'>
                        <img src={Sickness} alt="" />
                        <h4 className='fw-700 mb-0 ml-8'>Sickness, Accident, Injury</h4>
                      </div>
                      <div className='d-flex mt-10'>
                        <img src={Private} alt="" />
                        <h4 className='fw-700 mb-0 ml-8'>Private Vehicle Failure </h4>
                      </div>
                      <div className='d-flex mt-10'>
                        <img src={HomeEmergency} alt="" />
                        <h4 className='fw-700 mb-0 ml-8'>Home Emergency</h4>
                      </div>
                      <div className='d-flex mt-10'>
                        <img src={PreExisting} alt="" />
                        <h4 className='fw-700 mb-0 ml-8'>Pre-existing medical Conditions</h4>
                      </div>
                    </div>
                    <div className='column-5  sikness'>
                      <div className='d-flex'>
                        <img src={Public} alt="" />
                        <h4 className='fw-700 mb-0 ml-8'>Public Transport Failure</h4>
                      </div>
                      <div className='d-flex mt-10'>
                        <img src={EmergencyServices} alt="" />
                        <h4 className='fw-700 mb-0 ml-8'>Emergency services recall</h4>
                      </div>
                      <div className='d-flex mt-10'>
                        <img src={AndMany} alt="" />
                        <h4 className='fw-700 mb-0 ml-8'>And many more</h4>
                      </div>

                    </div>
                  </div>
                  <div className='px-20 py-20'>
                    <h4 className='fw-700'>
                      * Upgrade your booking and recieve a 100% refund if you cannot attend and can evidence one of the many reasons in our Terms andConditions, which you accept when you select a Refundable Booking.
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='column-3 your-booking'>
            <div>

              <div className='your-booking-bg p-20'>
                <div className='d-flex align-items-center'>
                  <img src={YourBooking} alt="" />
                  <h4 className='fw-700 mb-0 ml-10'>Your Booking</h4>
                </div>
                <div className='mt-15'>
                  <p className='fw-600 '> <span className='fw-800'>1 Ticket(s):</span> {state?.flightData?.classes}</p>
                </div>
                <div className='d-flex mt-15'>
                  <img src={Location} alt="" />
                  <p className='fw-600 ml-10'>{state?.flightData?.origin} TO {state?.flightData?.desti}</p>
                </div>
                <div className='d-flex mt-15'>
                  <img src={calendar} alt="" />
                  <p className='fw-600 ml-10'>{state?.fromDate} - {state?.toDate}</p>
                </div>
                <div className='d-flex mt-15'>
                  <img src={Taxes} alt="" />
                  <p className='fw-600 ml-10'>Taxes and Service Charges are Included</p>
                </div>
                {/* <div className='d-flex justify-content-between fw-700 mt-15'>
                <h4 className='fw-700'>1 Adult</h4>
                <h4 className='fw-700'>$1,200</h4>
              </div>
              <div className='d-flex justify-content-between mt-15'>
                <h4 className='fw-700'>1 Adult</h4>
                <h4 className='fw-700'>$1,200</h4>
              </div> */}
                <div className='dotted-border my-20'>

                </div>
                <div className='d-flex justify-content-between'>
                  <h4 className='fw-800'>Total</h4>
                  <h4 className='fw-700 greenColor'>₤{state?.totalPrice}</h4>
                </div>
              </div>
              {/* <div className='mt-15'>
              <Button variant="contained"
                className="orangeBtn text-white p-15 fw-700"

                fullWidth>
                Book Now
              </Button>
            </div> */}
            </div>
            <div className='our-guarantees mt-15'>
              <div className='text-center py-15 border-bottom'>
                <h2 className='mb-0'>Our Guarantees</h2>
              </div>
              <div className='p-12'>

                <div className='d-flex py-8'>
                  <img src={BestPrice} alt="" />
                  <p className='mb-0 ml-10 fs-14 fw-600'>Best Price Guarantee</p>
                </div>
                <div className='d-flex py-8' >
                  <img src={TopQuality} alt="" />
                  <p className='mb-0 ml-10 fs-14 fw-600'>Top quality Customers Support</p>
                </div>
                <div className='d-flex py-8'>
                  <img src={Over150} alt="" />
                  <p className='mb-0 ml-10 fs-14 fw-600'>Over 150 Travel Experts to help you</p>
                </div>
                <div className='d-flex py-8'>
                  <img src={NoExtra} alt="" />
                  <p className='mb-0 ml-10 fs-14 fw-600'>No extra charges for any card type</p>
                </div>

              </div>

            </div>
          </div>



        </div>
      </Container>



      <Modal
        open={open}
        onClose={handleClosepop}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title text-center" variant="h6" component="h2">
            Thank you for booking with us
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            You will get a confirmation from us very soon
          </Typography>
        </Box>
      </Modal>
      <BookSection />
      <Footer />
    </>
  )
}

export default CheckOut