import React from 'react';
import './ReviewCards.css';
import { Container } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ReviewCards from './ReviewCards';
import userprofile from '../imagesmy/usericon.svg'
import stars from '../imagesmy/fivestart.svg'

const ReviewCardData = [
    {
        userprofile: userprofile,
        name: 'Lara Shade Opowu',
        reviewText: 'Bliss Flights is efficient, accessible and their prices are quite reasonable. Also, their customer services is 100percent. My encounter when I needed to extend stay in Nigeria and change my return ticket was a good one because I got the best support ever. I was given full concentration until I got a fare deal.',
        rating: '5.0/5.0',
        stars: stars,
    },
    
    {
        userprofile: userprofile,
        name: 'Musiliat Lolade Akanji',
        reviewText: 'The service is very good and has been reliable and trustworthy.',
        rating: '5.0/5.0',
        stars: stars,
    },
    {
        userprofile: userprofile,
        name: 'Chijioke',
        reviewText: 'The service is very good and has been reliable and trustworthy.',
        rating: '4.8/5.0',
        stars: stars,
    },
    {
        userprofile: userprofile,
        name: 'Oliver',
        reviewText: 'I received very good and awesome service..',
        rating: '4.8/5.0',
        stars: stars,
    },
    {
        userprofile: userprofile,
        name: 'Lara Shade Opowu',
        reviewText: 'Bliss Flights is efficient, accessible and their prices are quite reasonable. Also, their customer services is 100percent. My encounter when I needed to extend stay in Nigeria and change my return ticket was a good one because I got the best support ever. I was given full concentration until I got a fare deal.',
        rating: '5.0/5.0',
        stars: stars,
    },
    {
        userprofile: userprofile,
        name: 'Musiliat Lolade Akanji',
        reviewText: 'The service is very good and has been reliable and trustworthy.',
        rating: '5.0/5.0',
        stars: stars,
    },
    {
        userprofile: userprofile,
        name: 'Chijioke',
        reviewText: 'The service is very good and has been reliable and trustworthy.',
        rating: '4.8/5.0',
        stars: stars,
    },
    {
        userprofile: userprofile,
        name: 'Oliver',
        reviewText: 'I received very good and awesome service..',
        rating: '4.8/5.0',
        stars: stars,
    },
   
  
    // ... other data entries
];


const ReviewSection = () => {
    const sliderSettings = {
        dots: true,
        loop: true,
        centerMode: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,  // Add autoplay property
        autoplaySpeed: 2000,  // Set autoplay speed in milliseconds
        centerPadding: '0',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <section className='py-60 review-section'>
            <h2 className='text-center'>What our Customers says about Us</h2>
            <Container maxWidth="lg">
                <Slider {...sliderSettings}>
                    {ReviewCardData.map((eachReview, index) => (
                        <div key={index}>
                            <ReviewCards
                                userprofile={eachReview.userprofile}
                                name={eachReview.name}
                                reviewText={eachReview.reviewText}
                                stars={eachReview.stars}
                            />
                        </div>
                    ))}
                </Slider>
                <div>
                </div>
               
            </Container>
            <div className='text-center'>

<button className='view-all-btn'>View All</button>
</div>
        </section>
    );
};

export default ReviewSection;
