import React from "react";
import { Container, Grid, Paper, Typography } from "@mui/material";
import blogcover from "../imagesmy/blog1cover.webp";
import BaaniSpaKagiMaldives from "../imagesmy/BaaniSpaKagiMaldives.jpg";
import VilamendhooMaldives from "../imagesmy/VilamendhooMaldives.jpg";
import SandiesBathala from "../imagesmy/SandiesBathala.jpg";
import AdaaranSelectHudhuranfushi from "../imagesmy/AdaaranSelectHudhuranfushi.png";
import KuramathiMaldives from "../imagesmy/KuramathiMaldives.jpg";
import DhigaliMaldives from "../imagesmy/DhigaliMaldives.jpg";
import KomandooIsland from "../imagesmy/KomandooIsland.jpg";
import BurjKhalifa from "../imagesmy/duabi-4.jpg";
import dubai2 from "../imagesmy/duabi-2.jpg";
import dubai3 from "../imagesmy/duabi-3.jpg";
import dubai4 from "../imagesmy/duabi-4.jpg";
import dubai5 from "../imagesmy/duabi-5.jpg";

import Footer from "./Footer";
import { Topnav } from "./Topnav";
import Navbar from "./Navbar";

const BlogDetaailsDubai = () => {
    return (
        <>
            <Topnav />
            <Navbar />
            <Container maxWidth="lg" style={{ marginTop: "20px" }}>
                <Grid container spacing={3} justifyContent="center">
                    <Grid item xs={12}>
                        <Paper style={{ padding: "20px" }} elevation={3}>
                            <img
                                src={dubai3}
                                alt="Blog Cover"
                                style={{ width: "100%", height: "500px " }}
                            />
                            <Typography variant="h4" style={{ marginTop: "20px" }}>
                                Our top reasons to visit the Dubai
                            </Typography>
                            <Typography variant="subtitle1" color="textSecondary">
                                Published on: OCt 26, 2023
                            </Typography>
                            <Typography variant="body1" style={{ marginTop: "20px" }}>
                                {/* Your blog content goes here */}
                                Dubai is a city synonymous with luxury and extravagance. The
                                architectural marvels, ingenious man-made islands and
                                colosseum-sized shopping malls never fail to dazzle visitors. We
                                explore five popular neighbourhoods in this urban sprawl to help
                                you choose where to stay.
                            </Typography>

                            {/* Vilamendhoo Island Resort */}
                            <Typography variant="h6" style={{ marginTop: "20px" }}>
                                Best for stopovers and shopping: Downtown Dubai
                            </Typography>
                            <img
                                src={BurjKhalifa}
                                alt="dubai"
                                style={{ width: "100%", height: "auto" }}
                            />
                            <Typography variant="body1" style={{ marginTop: "10px" }}>
                                Downtown Dubai is a testament to Dubai's architectural prowess and vibrant energy. This area is perfect for those with limited time, as it’s close to the airport and has so much to see within walking distance. There’s the iconic Burj Khalifa, the world's tallest building, the mesmerising shows at Dubai Fountain and Dubai Mall isn’t just for retail therapy – an aquarium, opera house and even an ice rink is under its roof. Add the presence of celebrity restaurants and trendy nightlife venues, and you’ll be ready to rest your head after dynamic days in this district. Our top pick is the Taj Dubai, which has rooms with jaw-dropping Burj Khalifa views.
                            </Typography>

                            {/* Sandies Bathala */}
                            <Typography variant="h6" style={{ marginTop: "20px" }}>
                                Best for sightseeing: Dubai Marina and Jumeirah Beach Residence
                            </Typography>
                            <img
                                src={dubai2}
                                alt="dubai"
                                style={{ width: "100%", height: "auto" }}
                            />
                            <Typography variant="body1" style={{ marginTop: "10px" }}>
                                Jumeirah Beach Residence is fantastic if you want to be out and about exploring. The lively beach promenade, known as ‘The Walk’ is lined with shops, eateries, pop-up markets and street entertainers. To be in the heart of the action, JA Ocean View Hotel is a fresh and contemporary hotel with sea views from every room. And don’t miss the Friday Bubbalicious Brunch at the  Westin Dubai Mina Seyahi Beach Resort & Marina where you can expect free-flowing Champagne and an exquisite buffet of lobster, steaks and roasts.

                                A little inland from the beach, you’ll find Dubai Marina, a skyscraper-surrounded waterfront enclave with luxury yachts and speedboats gliding through its calm waters. Take leisurely promenade strolls (or catch a water taxi), head to the upscale Marina Mall for shopping and sky-high dining or hop on the Dubai Metro to explore other parts of the city. The Millennium Place Marina is only a 5-minute walk from the metro and is perfect for a great-value base.
                            </Typography>

                            {/* All-Inclusive Escape Section */}
                            {/* Add similar sections for Adaaran Select Hudhuranfushi and Emerald Maldives */}
                            {/* Adaaran Select Hudhuranfushi */}
                            <Typography variant="h6" style={{ marginTop: "20px" }}>
                                Best for all-inclusive family fun: Dubai Islands
                            </Typography>
                            <img
                                src={dubai3}
                                alt="dubai"
                                style={{ width: "100%", height: "auto" }}
                            />
                            <Typography variant="body1" style={{ marginTop: "10px" }}>
                                Dubai is a safe and welcoming destination that will captivate young imaginations. Aside from Aladdin-style desert adventures and mindboggling views from the top of the world, one of the top attractions for families is Dubai Parks and Resorts. It’s a sprawling complex about 30 minutes from downtown and is home to three theme parks and a water park, including Legoland® Dubai and Hollywood-inspired Motiongate™. One of our favourite places for families to stay in Dubai is Centara Mirage Beach Resort Dubai, a themed resort with an all-inclusive option, kids’ clubs and plenty of spots to cool off, including two beachfront swimming pools with waterslides, a splash zone and a lazy river. It’s also close to downtown and the spice-scented souks of Deira for a hit of culture.
                            </Typography>

                            {/* Adaaran Select Hudhuranfushi */}
                            <Typography variant="h6" style={{ marginTop: "20px" }}>
                                Best for Instagram vibes: The Palm
                            </Typography>
                            <img
                                src={dubai4}
                                alt="dubai"
                                style={{ width: "100%", height: "auto" }}
                            />
                            <Typography variant="body1" style={{ marginTop: "10px" }}>
                                If you crave the glitz and glam, Palm Jumeirah is your go-to address. This palm-shaped wonderland is a playground for the posh, featuring standout retreats like Sofitel Dubai The Palm Resort & Spa and W Dubai – The Palm, as well as legendary hotels: Atlantis The Royal, Dubai’s hottest new hangout, and Atlantis The Palm, its sister hotel next door. Atlantis guests have unlimited access to Aquaventure Waterpark, but even if you’re not checked in here, it’s well worth a visit and we can arrange a day pass.

                                You’ll get the best panoramas of The Palm from above and you can do this in a couple of ways – opt for a scenic helicopter flight, experience the Dubai Balloon or visit The View at the Palm’s 360-degree observation deck on level 52 of the Palm Tower.
                            </Typography>

                            {/* Emerald Maldives */}
                            <Typography variant="h6" style={{ marginTop: "20px" }}>
                                Best for gourmet guests: Jumeirah
                            </Typography>
                            <img
                                src={dubai5}
                                alt="dubai"
                                style={{ width: "100%", height: "auto" }}
                            />
                            <Typography variant="body1" style={{ marginTop: "10px" }}>
                                Jumeirah, one of Dubai's most coveted stretches along the seafront, is peppered with some of the city’s most exclusive and mould-breaking hotels. There’s the glittering, wave-shaped Jumeirah Beach Hotel and the honey-hued Jumeirah Mina A’ Salam, as well as several other notable resorts from the Jumeirah chain, renowned for their world-class restaurants. Stay half-board at selected Jumeirah hotels, and you can go on a culinary adventure through Dubai, as complimentary dining at over 30 restaurants is included as part of the Jumeirah Flavours experience*.
                            </Typography>



                            {/* Kuramathi Maldives */}






                            {/* Escape and Relax Section */}
                            {/* Add similar sections for Kagi Maldives Spa Island and Komandoo Island Resort & Spa */}
                            {/* Kagi Maldives Spa Island */}







                        </Paper>
                    </Grid>
                </Grid>
            </Container>

            <Footer />
        </>
    );
};

export default BlogDetaailsDubai;
