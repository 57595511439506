import React, { useRef, useState, useEffect } from 'react';
import Footer from '../Footer';
import Navbar from '../Navbar';
import "./bookflight.css"
import { useLocation, Link, useNavigate } from 'react-router-dom'
// import AllData from '../../dashboard/data/AllData';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import { Box, TextField, Button, Modal, Typography, Checkbox, InputLabel } from "@material-ui/core";
import { Accordion, Container, Grid, Paper } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import emailjs from '@emailjs/browser';
import AllData from '../../dashboard/data/AllData';
import DepartureFlight from '../../imagesmy/DepartureFlight.svg'
import ReturnFlight from '../../imagesmy/ReturnFlight.svg'
import Clock from '../../imagesmy/clock.svg'
import BookNow from '../../imagesmy/bookNow.svg'
import CallNow from '../../imagesmy/callNow.svg'
import AirPlane from '../../imagesmy/airplane.svg'
import DotedLine from '../../imagesmy/dotedLine.svg'
import WIicon from '../../imagesmy/Iicon.svg'
import BIicon from '../../imagesmy/BIicon.svg'
import Tooltip from '@mui/material/Tooltip';
import BookSection from '../BookSection';







const style = {
  position: 'absolute',
  top: '20%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export default function BookFlight() {

  const [activeButton, setActiveButton] = useState('cheapest'); // Set an initial value for the active button

  // Function to handle radio button changes and set the active button


  // Define styles for the buttons
  const buttonStyles = {
    backgroundColor: '#2955DE',
    color: 'white',
  };

  // Define styles for the image inside the icon
  const iconStyles = {
    filter: 'brightness(1)',
    color: 'white'
  };
  const [checkboxes, setCheckboxes] = useState({
    chk: [],
  });

  const [formData, setFormData] = useState({
    input1: '',
    input2: '',
    input3: '',
    input4: '',
    input5: '',
    input6: '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   // Do something with the form data, like submitting it to a server
  //   console.log("formData", formData);
  // };
  //this section for open popup
  const [open, setOpen] = React.useState(false);

  const handleClosepop = () => setOpen(false);


  const [openModal, setOpenModal] = useState(false);
  openModal ? document.body.style.overflow = "hidden" : document.body.style.overflow = "auto";
  const [flightsdata, setflightsData] = useState([]);

  const [Origin, setOrigin] = useState("")
  const [Desti, setDesti] = useState("")
  const [Classes, setClasses] = useState("")
  const [Airline, setAirline] = useState("")
  const [Adult, setAdult] = useState("")
  const [Child, setChild] = useState("")
  const [Infant, setInfant] = useState("")

  const [pasName, setPasName] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [deptDate, setDeptDate] = useState("");
  const [returnDate, setReturnDate] = useState("");
  const [destidata, setdestiData] = useState([]);
  const [flyingTo, setFlyingTo] = useState("");
  const [flyingFrom, setFlyingFrom] = useState('');



  const [percentageValue, setPercentageValue] = useState(0)
  //this section for seasional fair 
  const [seasonaldata, setseasonalData] = useState([]);
  //this section for getting seasional  data from database


  const getSeason = async () => {
    const data = await AllData.getAllSeasonal();
    const selectedMonth = state.Fromdate.getMonth();
    setseasonalData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    data.docs.map((val, index) => {
      console.log(val.data())
      console.log(index)
      if (selectedMonth == index) {
        setPercentageValue(val.data().Amount)
        console.log('we have to update prices for %: ', val.data().Amount)
      }
    }

    )
  };
  //  const calculatePercentage = (amount) => {
  //   console.log(((amount/100)*percentageValue));
  //   return ((amount/100)*percentageValue);
  //  }

  useEffect(() => {
    handleClickOpen();
    getSeason();
  }, []);

  const { state } = useLocation();
  // just this change or sorting
  const handleClickOpen = (e) => {

    const updatedData = state.flightsdata.filter((curData) => {
      return curData.origin === state.From && curData.desti === state.To;

    })
    const sortByAdultPrice = updatedData => {
      const sorter = (a, b) => {
        return a.adult - b.adult
      };
      updatedData.sort(sorter);
      return updatedData;
    };

    setflightsData(sortByAdultPrice(updatedData))

  };

  const handleClickOpenModal = (origin, desti, airline, classes, adult, child, infant) => {


    setOpenModal(true)
    setOrigin(origin)
    setDesti(desti)
    setClasses(classes)
    setAdult(adult)
    setChild(child)
    setInfant(infant)
    setAirline(airline)



  }












  const handleClose = () => {
    setOpenModal(false)
  }

  const navigator = useNavigate();

  //this section for  email sending
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    handleClose();
    setOpen(true);
    emailjs.sendForm('service_b78n6te', 'template_r0p8nyn', form.current, 'U9N4oeLD7PnGGl6QO')
      .then((result) => {
        window.location.reload();
      }, (error) => {
      });
  };


  const handleRadioButtonChange = (value) => {
    setActiveButton(value);
  };

  const filteredFlights = flightsdata.filter(doc => {
    const price = parseFloat((((parseInt(doc.adult) + ((doc.adult / 100) * percentageValue))) * parseInt(state.Adult)) + (((parseInt(doc.infant) + ((doc.infant / 100) * percentageValue))) * parseInt(state.Infant)) + (((parseInt(doc.child) + ((doc.child / 100) * percentageValue))) * parseInt(state.Child))).toFixed(2);

    const airline = doc?.airline;

    const priceFilter = (() => {
      if (activeButton === 'cheapest') {
        return price >= 50 && doc?.fstop != "direct" && doc?.fstop != "Direct";
      } else if (activeButton === 'best') {
        return (doc?.fstop == "direct" || doc?.fstop == "Direct");
      } else if (activeButton === 'quickest') {
        return (doc?.fstop == "direct" || doc?.fstop == "Direct");
      }
      return true;
    })();

    const airlineFilter = checkboxes.chk.length === 0 || checkboxes.chk.includes(airline);

    return priceFilter && airlineFilter;
  });

  const filteredAirlines = flightsdata.map(doc => {
    return doc?.airline;
  });

  const handleChange = (value, e) => {
    const { checked } = e.target;
    const { chk } = checkboxes;
    if (checked) {
      setCheckboxes({
        chk: [...chk, value],
      });
    } else {
      setCheckboxes({
        chk: chk.filter((e) => e !== value),
      });
    }
  }

  const onChange1 = (event) => {
    setFlyingTo(event.target.value)
  };

  const onSearch1 = (searchTerm) => {
    setFlyingTo(searchTerm)
  };

  useEffect(() => {
    getDesti();
  }, []);

  const getDesti = async () => {
    const data = await AllData.getAllDesti();
    setdestiData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  const onChange = (event) => {
    setFlyingFrom(event.target.value);
  };

  const onSearch = (searchTerm) => {
    setFlyingFrom(searchTerm);
  };

  return (
    <>
      <Navbar />
      <div className='bookFlight-Main '>
        <Container maxWidth="lg">
          <div className="  grid-container py-35">
            <div className='flight-tickets'>
              <div >
                <p className='fw-700 mb-10'>Your Flight Tickets</p>
             
                <div className='flight-book-bg p-12 mb-10'>
                  <div className='d-flex align-items-center '>
                    <img src={DepartureFlight} alt="" />
                    <div className='ms-10'>
                      <p className='fw-700'>Departure Flight</p>
                      <p className='pText'>{state.Fromdate.toDateString()}</p>
                    </div>
                  </div>

                </div>
                <div className='flight-book-bg p-12 mb-10'>
                  <div className='d-flex align-items-center '>
                    <img src={ReturnFlight} alt="" />
                    <div className='ms-10'>
                      <p className='fw-700'>Return Flight</p>
                      <p className='pText'>{state.Todate.toDateString()}</p>
                    </div>
                  </div>

                </div>
                <h3 className='fw-700 mb-10'>{checkboxes?.chk?.length} flights</h3>

              </div>
              <hr />
              <div>
                {filteredAirlines?.map((data) => {
                  return (
                    <div className='d-flex justify-content-between'>
                      <div>
                        <Checkbox
                          onChange={(e) => handleChange(data, e)}
                          name="checkbox1"
                          color="Ocolor"
                          background="Ocolor"
                          inputProps={{ 'aria-label': 'Checkbox 1' }}
                        />
                        <label className='Ocolor'>{data}</label>
                      </div>
                    </div>
                  )
                })}

                

               

                

               
              </div>
              <div className='form-bg'>
                <h3 className='fw-700 text-center mb-10'>Call Back Request</h3>
                <form ref={form} onSubmit={sendEmail}>
                  <div className='mb-10'>
                    <InputLabel htmlFor="input1 " className='text-black fw-800 fs-14 mb-5'>Passenger Name</InputLabel>
                    <TextField
                      variant="outlined"
                      name="name"
                      fullWidth
                      className='myInputs mb-10'
                      placeholder='Enter Passenger Name'
                      required
                    />
                  </div>
                  <div className='mb-10'>
                    <InputLabel htmlFor="input2" className='text-black fw-800 fs-14 mb-5'>Departure Airport</InputLabel>
                    <TextField
                      variant="outlined"
                      fullWidth
                      className='myInputs mb-10'
                      onChange={onChange}
                      placeholder="City, Airport, Country"
                      value={flyingFrom}
                      name='flying_from'
                      required

                    />
                    <div className="dropdown">
                      {destidata
                        .filter((item) => {

                          const searchTerm = flyingFrom.toLowerCase();
                          const fullName = item.fullplace.toLowerCase();

                          return (
                            searchTerm &&
                            fullName.includes(searchTerm) &&
                            fullName !== searchTerm
                          );
                        })
                        .slice(0, 10)
                        .map((item) => (
                          <div
                            onClick={() => onSearch(item.fullplace)}
                            className="dropdown-row"
                            key={item.fullplace}
                          >
                            {item.fullplace}
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className='mb-10'>
                    <InputLabel htmlFor="input3" className='text-black fw-800 fs-14 mb-5'>Return Airport</InputLabel>
                    <TextField
                      variant="outlined"
                      placeholder="City, Airport, Country"
                      value={flyingTo}
                      name='flying_to'
                      onChange={onChange1}
                      fullWidth
                      className='myInputs mb-10'
                      required

                    />
                    <div className="dropdown">
                      {destidata
                        .filter((item) => {

                          const searchTerm = flyingTo.toLowerCase();
                          const fullName = item.fullplace.toLowerCase();

                          return (
                            searchTerm &&
                            fullName.includes(searchTerm) &&
                            fullName !== searchTerm
                          );
                        })
                        .slice(0, 10)
                        .map((item) => (
                          <div
                            onClick={() => onSearch1(item.fullplace)}
                            className="dropdown-row"
                            key={item.fullplace}
                          >
                            {item.fullplace}
                          </div>

                        ))}
                    </div>
                  </div>
                  <div className='mb-10'>
                    <InputLabel htmlFor="input4" className='text-black fw-800 fs-14 mb-5'>Phone Number</InputLabel>
                    <TextField
                      variant="outlined"
                      name="phone"
                      fullWidth
                      className='myInputs mb-10'
                      placeholder='Enter Phone Number'
                      required
                    />
                  </div>
                  <div className='mb-10'>
                    <InputLabel htmlFor="input5" className='text-black fw-800 fs-14 mb-5'>Departure Date</InputLabel>
                    <TextField
                      variant="outlined"
                      name="dept_date"
                      fullWidth
                      className='myInputs mb-10'
                      placeholder='We, 12 jun'
                      type='date'
                      required
                    />
                  </div>
                  <div className='mb-10'>
                    <InputLabel htmlFor="input6" className='text-black fw-800 fs-14 mb-5'>Return Date</InputLabel>
                    <TextField
                      variant="outlined"
                      name="return_date"
                      fullWidth
                      className='myInputs mb-10'
                      placeholder='We, 12 jun'
                      type='date'
                      required
                    />
                  </div>

                  <input type="hidden" name="adult_price" value={state.Adult} />
                  <input type="hidden" name="child_price" value={state.Child} />
                  <input type="hidden" name="infant_price" value={state.Infant} />
                  <input type="hidden" name="origin" value={state?.From} />
                  <input type="hidden" name="destination" value={state?.To} />
                  <input type="hidden" name="from" value={state.Fromdate.toDateString()} />
                  <input type="hidden" name="to" value={state.Todate.toDateString()} />

                  <Button
                    type="submit"
                    variant="contained"
                    className="subBtn p-15 fw-700"
                    fullWidth
                  >
                    Submit
                  </Button>
                </form>
              </div>
            </div>
            <div className=" flight-detail">
              <h2 className='flight-info'>Select your Flight </h2>

              <div className='flight-card-bg grid-container-flight mb-20'>
                <div className="cheapest p-10">
                  <div
                    className='bestBtn d-flex'
                    style={activeButton === 'cheapest' ? buttonStyles : {}}
                  >
                    <input
                      type="radio"
                      id="cheapestRadio"
                      name="radioButtons"
                      value="cheapest"
                      checked={activeButton === 'cheapest'}
                      onChange={() => handleRadioButtonChange('cheapest')}
                      hidden
                    />
                    <label htmlFor="cheapestRadio">
                      <p className='fw-700 '>Cheapest Flights</p> 
                      <p className='fw-700 tag-msg'>Affordable | Budget-friendly | Cost-effective</p> 
                    

                    </label>
                    <div>
                    &nbsp;&nbsp;&nbsp; <img src={activeButton === 'cheapest' ? WIicon : BIicon} alt="" style={iconStyles} />
                    </div>
                  </div>
                </div>
                <div className='best p-10'>
                  <div
                    className='bestBtn d-flex'
                    style={activeButton === 'best' ? buttonStyles : {}}
                  >
                    <div>

                    </div>
                    <input
                      type="radio"
                      id="bestRadio"
                      name="radioButtons"
                      value="best"
                      checked={activeButton === 'best'}
                      onChange={() => handleRadioButtonChange('best')}
                      hidden // Hide the radio input
                    />
                    <label htmlFor="bestRadio">

                      <p className='fw-700 Best Flights'>Best Flights</p>
                      <p className='fw-700 tag-msg'>Luxurious | Premium | Top-notch</p> 


                    

                    </label>
                    <div>
                    &nbsp;&nbsp;&nbsp;   <img src={activeButton === 'best' ? WIicon : BIicon} alt="" style={iconStyles} />
                    </div>
                  </div>
                </div>
                <div className='quickest p-10'>
                  <div
                    className='bestBtn d-flex'
                    style={activeButton === 'quickest' ? buttonStyles : {}}
                  >
                    <input
                      type="radio"
                      id="quickestRadio"
                      name="radioButtons"
                      value="quickest"
                      checked={activeButton === 'quickest'}
                      onChange={() => handleRadioButtonChange('quickest')}
                      hidden
                    />
                    <label htmlFor="quickestRadio">
                      <p className='fw-700 '>Quickest Flights</p>
                      <p className='fw-700 tag-msg'>Efficient | Time-saving | Swift</p> 


                     

                    </label>
                    <div>
                    &nbsp;&nbsp;&nbsp;   <img src={activeButton === 'quickest' ? WIicon : BIicon} alt="" style={iconStyles} />
                    </div>
                  </div>
                </div>
              </div>

              <DialogContent>
                {filteredFlights.length ? filteredFlights.map((doc, index) => {

                  const totalPriceOfFlight = parseFloat((((parseInt(doc.adult) + ((doc.adult / 100) * percentageValue))) * parseInt(state.Adult)) + (((parseInt(doc.infant) + ((doc.infant / 100) * percentageValue))) * parseInt(state.Infant)) + (((parseInt(doc.child) + ((doc.child / 100) * percentageValue))) * parseInt(state.Child))).toFixed(2);

                  const adultPrice = parseFloat((((parseInt(doc.adult) + ((doc.adult / 100) * percentageValue))) * parseInt(state.Adult))).toFixed(2);

                  const childPrice = parseFloat(((parseInt(doc.child) + ((doc.child / 100) * percentageValue))) * parseInt(state.Child)).toFixed(2);

                  const infantPrice = parseFloat(((parseInt(doc.infant) + ((doc.infant / 100) * percentageValue))) * parseInt(state.Infant)).toFixed(2);

                  const firstWordOrigin = doc?.origin.split(' ')[0];
                  const firstWordDesti = doc?.desti.split(' ')[0];
                  const fstop = doc?.fstop.split(' ')[0];

                  return (

                    <div key={index} className='Card-Main-Container grid-container-flight  flight-card-bg mb-20 p-0'>

                      <div className=' Card-Left-Container'>

                        <div className='Left-Top'>



                        </div>
                        <div className='logo-container'><img className="cellImg text-center flight-logo" src={doc.img} alt="avatar" />
                          <h5 className='mb-12'>{doc.airline}</h5>
                          <span className='btn btnGreen text-white'>{doc.classes}</span>
                        </div>
                        <div className='Left-Time mt-30'>
                          <img src={Clock} alt="" />
                          <p className='fs-14 fw-700'>Total Flight Duration</p>

                        </div>
                        <div className='Left-Time-Time'>

                          <h5 className='fs-18 fw-800'>{doc.fduration}</h5>
                        </div>
                      </div>
                      <div className='Card-Center-Container'>


                        {/* <div className='departure-date-container'>
                      <h5> Departure Date: {state.Fromdate.toDateString()}</h5>
                      </div>  */}
                        {/* <div className='From-Stop-To-Caontainer d-flex'>
                      <div className='Center-Departure1'>
                        <h4>Travel From</h4>
                      </div>
                      <div className='Center-Stop1'>
                      <h4>Stop Over</h4>
                      </div>
                      <div className='Center-Travel-To1'>
                      <h4>Travel To</h4>
                      </div>
                      </div> */}
                        <div className='From-Stop-To-Caontainer'>
                          <div className='Center-Departure'>

                            {/* <h3 className='fw-800 fs-28'>{doc.timeFrom}</h3> */}


                            <h3 className='fw-800 fs-28'>{doc.timeFrom}</h3>
                         

                            <Tooltip title={
                              <div>
                                <img src={AirPlane} alt="Your Image" width="100" height="100" />
                                <p>{doc.origin}</p>
                              </div>
                            } arrow>
                              <p className='pText' style={{ textTransform: "uppercase" }}>{firstWordOrigin.endsWith(',') ? firstWordOrigin.slice(0, -1) : firstWordOrigin}</p>
                            </Tooltip>
                          </div>
                          <div className='airplane-line'>
                            {/* <p className='text-center fw-700'>{doc.fduration}</p> */}

                            <div class=" position-relative text-center">
                              <img src={DotedLine} alt="" className='dotted-line' />

                            </div>
                            <div className=' d-flex justify-content-center'>
                              {/* <p className='Ocolor'>
                                1 Stop  </p> */}
                                {/* <p className='pText text-center'>
                                  <Tooltip title={
                                  <div>
                                    <img src={AirPlane} alt="Your Image" width="100" height="100" />
                                    <p>{doc.fstop}</p>
                                  </div>
                                } arrow>
                                  <p className='pText' style={{ textTransform: "uppercase" }}>{fstop.endsWith(',') ? fstop.slice(0, -1) : fstop}</p>
                                </Tooltip></p> */}

                            </div>
                            <div className='Center-Stop2 position-relative'>
                              {/* <h5>{doc.fstop}</h5> */}

                            </div>
                          </div>
                          <div className='Center-Travel-To '>

                            {/* <h3 className='fw-800 fs-28'>{doc.timeTo}</h3> */}
                          
                            <h3 className='fw-800 fs-28'>{doc.timeTo}</h3>
                            <Tooltip title={
                              <div>
                                <img src={AirPlane} alt="Your Image" width="100" height="100" />
                                <p>{doc.desti}</p>
                              </div>
                            } arrow>
                              <p className='pText' style={{ textTransform: "uppercase" }}>{firstWordDesti.endsWith(',') ? firstWordDesti.slice(0, -1) : firstWordDesti}</p>
                            </Tooltip>

                          </div>

                        </div>

                        <div className='From-Stop-To-Caontainer mt-100'>
                          <div className='Center-Departure'>
                          <h3 className='fw-800 fs-28'>{doc.retunrtimefrom}</h3>  

                            <Tooltip title={
                              <div>
                                <img src={AirPlane} alt="Your Image" width="100" height="100" />
                                <p>{doc.desti}</p>
                              </div>
                            } arrow>
                              <p className='pText' style={{ textTransform: "uppercase" }}>{firstWordDesti.endsWith(',') ? firstWordDesti.slice(0, -1) : firstWordDesti}</p>
                            </Tooltip>
                          </div>

                          <div className='airplane-line'>
                            {/* <p className='text-center fw-700'>{doc.fduration}</p> */}
                            <div class=" position-relative text-center">
                         
                          
                              <img src={DotedLine} alt="" className='dotted-line ' />

                         
                           

                            </div>

                            <div className=' d-flex justify-content-center'>
                              {/* <p className='Ocolor'>
                                1 Stop  </p> */}
                                {/* <p className='pText text-center'><Tooltip title={
                                  <div>
                                    <img src={AirPlane} alt="Your Image" width="100" height="100" />
                                    <p>{doc.fstop}</p>
                                  </div>
                                } arrow>
                                  <p className='pText' style={{ textTransform: "uppercase" }}>{fstop.endsWith(',') ? fstop.slice(0, -1) : fstop}</p>
                                </Tooltip>
                                </p> */}

                            </div>

                            {/* <div className='return-return'>
                          
                        <h5>Return Date: {state.Todate.toDateString()}</h5>
                      </div> */}
                            <div className='Center-Stop2'>
                              {/* <h5>{doc.fstop}</h5> */}
                            </div>
                          </div>

                          <div className='Center-Travel-To '>
                            {/* <h5>{doc.origin}</h5> */}
                          
                            <h3 className='fw-800 fs-28'>{doc.retunrtimeto}</h3>
                            <Tooltip title={
                              <div>
                                <img  src={AirPlane} alt="Your Image" width="100" height="100" />
                                <p>{doc.origin}</p>
                              </div>
                            } arrow>
                              <p className='pText' style={{ textTransform: "uppercase" }}>{firstWordOrigin.endsWith(',') ? firstWordOrigin.slice(0, -1) : firstWordOrigin}</p>
                            </Tooltip>
                           
                          </div>
                        </div>
                        {/* <div className='Center-Bottom-Bar'>
                        <h4 className='flightClass'>Flight Class:</h4>
                        <h4 className='flightClassB'>{doc.classes}</h4>
                                              </div> */}

                      </div>
                      <div className=' Card-Right-Container'>
                      <div className='d-flex justify-content-center tag-wraaper'>
                          {adultPrice > 0 && <div className='adultBg p-10'>

                            <Tooltip title={
                              <div>
                                <h3> ₤{adultPrice}</h3>
                              </div>
                            } arrow>
                              <h4 className='fs-14'>Adult</h4>
                            </Tooltip>
                          </div>}
                          {childPrice > 0 && <div className='adultBg p-10'>
                            <Tooltip title={
                              <div>
                                <h3> ₤{childPrice}</h3>
                              </div>
                            } arrow>
                              <h4 className='fs-14'>Child</h4>
                            </Tooltip>
                            {/* <h4 className='fs-14'>Child</h4> */}
                          </div>}
                          {infantPrice > 0 && <div className='adultBg p-10'>
                            <Tooltip title={
                              <div>
                                <h3> ₤{infantPrice}</h3>
                              </div>
                            } arrow>
                              <h4 className='fs-14'>Infant</h4>
                            </Tooltip>
                            {/* <h4 className='fs-14'>Infant</h4> */}
                          </div>}
                        </div>


                        <div className='Right-Fare-Total d-flex text-center justify-content-center Ocolor fs-30 mt-20 fw-800'>
                          <h4>£</h4>

                          <h3 className='ms-10'>{totalPriceOfFlight}</h3>
                        </div>

                        <Button className='btn subBtn fs-18 fw-700 p-15-10 mt-15' onClick={() => navigator("/checkout", {
                          state: { flightData: doc,
                             totalPrice: totalPriceOfFlight,
                              adultPrice: adultPrice,
                               childPrice: childPrice,
                                infantPrice: infantPrice,
                                 fromDate: state.Fromdate.toDateString(),
                                  toDate: state.Todate.toDateString(),
                                   classes:doc.classes,
                                   timeFrom: doc.timeFrom,
                                   timeTo: doc.timeTo,
                                   retunrtimefrom: doc.retunrtimefrom,
                                   retunrtimeto: doc.retunrtimeto
                                  },
                        })} >Book Now
                          <img src={BookNow} alt="" className='ms-10' />
                        </Button>

                        {/* <Button className='btn subBtn fs-18 fw-700 p-15-10 mt-15' onClick={() => { handleClickOpenModal(doc.origin, doc.desti, doc.airline, doc.classes, parseFloat((((parseInt(doc.adult) + ((doc.adult / 100) * percentageValue))) * parseInt(state.Adult))).toFixed(2), parseFloat(((parseInt(doc.child) + ((doc.child / 100) * percentageValue))) * parseInt(state.Child)).toFixed(2), parseFloat(((parseInt(doc.infant) + ((doc.infant / 100) * percentageValue))) * parseInt(state.Infant)).toFixed(2)) }} >Book Now
                        <img src={BookNow} alt="" className='ms-10' />
                      </Button> */}


                        <div className='btnCall d-flex align-items-center pl-10 pr-10 mt-20'>
                          <div>
                            <img src={CallNow} alt="" />
                          </div>
                          <div className=' pl-23'>
                            <p className='fs-12 fw-700 mb-0'>Call Now</p>
                            <a className='fnumber btn text-black text-center fw-700' href="tel:020-3927-7690">

                              020 3927 7690</a>
                          </div>

                        </div>
                      </div>


                    </div>

                  )



                }) : <h3 className='text-center mt-5'>No record found</h3>}

                {flightsdata.length === 0 &&




                  <div className='Second-From'>
                    <h1>Oops, we did not found any data against your request.</h1>
                    <h2>Our team has been notified. We are on it!</h2>
                    <Link to='/' style={{ textDecoration: 'none' }} > <Button  >Retry</Button></Link>

                  </div>


                }

              </DialogContent>
            </div>

          </div>
        </Container>
        {openModal &&
          <div className="modal">
            <div onClick={handleClose} className="overlay"></div>
            <div className="modal-content">
              <h2>Booking Details</h2>
              <button className="close-modal" onClick={handleClose}>
                CLOSE
              </button>
              <div className='srollable-Container'>
                <div className='model-flight-info'>

                  <div className='airline-Name'>
                    <p>Airline:</p>
                    <div className='airline-name-container'> <p>{Airline}</p> </div>

                  </div>
                  <div className='Travel-From'>
                    <p>Travel From:</p>
                    <div className='from-container'> <p>{Origin}</p> </div>
                  </div>
                  <div className='Travel-To'>
                    <p>Travel To:</p>
                    <div className='To-container'> <p>{Desti}</p> </div>
                  </div>
                  <div className='Flight-Class'>
                    <p>Class:</p>
                    <div className='class-name-container'> <p>{Classes}</p> </div>
                  </div>
                  <div className='Adult-Fare'>
                    <p>Adult Fare:</p>
                    <p1>₤{Adult}</p1>
                  </div>
                  <div className='Child-Fare'>
                    <p>Child Fare:</p>
                    <p1>₤{Child}</p1>
                  </div>
                  <div className='Infant-Fare'>
                    <p>Infant Fare:</p>
                    <p1>₤{Infant}</p1>
                  </div>
                  <div className='Total-Fare'>
                    <p>Total Fare:</p>
                    <p1>₤{parseFloat((parseInt(Adult) + parseInt(Child) + parseInt(Infant))).toFixed(2)}</p1>
                  </div>

                </div>
                <div className='hr-Container'><hr /></div>
                <form >
                  <div className='Input-Form-Container'>
                    <div className='Input-Container'>
                      <h3>Passenger Details</h3>
                    </div>
                    <div className='sub-Heading-Container'>
                      <p>Please tell us who will be checking in must be 18 or older</p>
                    </div>
                    <DialogContent>
                      <div className='F-name-Textfield-Container'>
                        < input
                          margin="dense"
                          name="airline"
                          label="First Name"
                          type="hidden"
                          fullWidth
                          variant="outlined"
                          value={Airline}
                        />
                        < input
                          margin="dense"
                          name="origin"
                          label="First Name"
                          type="hidden"
                          fullWidth
                          variant="outlined"
                          value={Origin}
                        />
                        < input
                          margin="dense"
                          name="desti"
                          label="First Name"
                          type="hidden"
                          fullWidth
                          variant="outlined"
                          value={Desti}
                        />
                        < input
                          margin="dense"
                          name="classes"
                          label="First Name"
                          type="hidden"
                          fullWidth
                          variant="outlined"
                          value={Classes}
                        />
                        < input
                          margin="dense"
                          name="adult"
                          label="First Name"
                          type="hidden"
                          fullWidth
                          variant="outlined"
                          value={Adult}
                        />
                        < input
                          margin="dense"
                          name="infant"
                          label="First Name"
                          type="hidden"
                          fullWidth
                          variant="outlined"
                          value={Infant}
                        />
                        < input
                          margin="dense"
                          name="child"
                          label="First Name"
                          type="hidden"
                          fullWidth
                          variant="outlined"
                          value={Child}
                        />
                        < input
                          margin="dense"
                          name="total"
                          label="First Name"
                          type="hidden"
                          fullWidth
                          variant="outlined"
                          value={parseInt(Adult) + parseInt(Child) + parseInt(Infant)}
                        />
                        < input
                          margin="dense"
                          name="departure-date"
                          label="First Name"
                          type="hidden"
                          fullWidth
                          variant="outlined"
                          value={state.Fromdate.toDateString()}
                        />
                        < input
                          margin="dense"
                          name="return-date"
                          label="First Name"
                          type="hidden"
                          fullWidth
                          variant="outlined"
                          value={state.Todate.toDateString()}
                        />

                        <div >
                          <h5>Contact Name:</h5>
                        </div>
                        <div className='F-name-Textfield'>


                          < TextField
                            required
                            autoFocus
                            margin="dense"
                            name="f_name"
                            label="First Name"
                            type="text"
                            fullWidth
                            variant="outlined"
                          />

                        </div>
                      </div>
                      <div className='L-name-Textfield'>
                        < TextField
                          require
                          margin="dense"
                          name="l_name"
                          label="Last Name"
                          type="text"
                          fullWidth
                          variant="outlined"
                        />
                      </div>
                      <div className='Contact-Number-Container'>
                        <div><h5>Contact Nunmber:</h5></div>
                        <div className='phone-Number-Textfield'>
                          < TextField
                            required
                            margin="dense"
                            name="number"
                            label="Contact No"
                            type="number"
                            fullWidth
                            variant="outlined"
                          />
                        </div>
                      </div>
                      <p className='email-above-p'>We ask for your mobile number so we can call or text you about changes to your itinerary, Are provide helpfull information about your trip.</p>
                      <div className='email-above-hr'> <hr /></div>
                      <di className='After-hr-Container'><p>Where should we send your confirmation?</p>
                        <hr />
                        <p1>Please enter the email address where you would like to recieve your confirmation.</p1>
                      </di>

                      <div><h5>Email Address:</h5></div>

                      <div className='email-container'>

                        <TextField

                          margin="dense"

                          name='email'
                          label="Email"
                          type="Email"
                          fullWidth
                          variant="outlined"
                        />
                      </div>
                      <hr />
                      <di className='Checkbox-Container'>
                        <Checkbox />
                        <p>By selecting to complete this booking i acknowledge that i have read and accept the<Link to='/terms_conditions'> Terms & Conditions</Link></p>
                      </di>
                    </DialogContent>

                    <DialogActions>
                      <div className='last-btn'>
                        <button className='last-button' type="submit" value="Send" >Complete Booking</button>

                      </div>

                    </DialogActions>
                  </div>

                </form>
              </div>

            </div>
          </div>

        }

        <div>

          <Modal
            open={open}
            onClose={handleClosepop}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Thank you for booking with us.
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                You will get a confirmation from us very soon
              </Typography>
            </Box>
          </Modal>
        </div>
      </div>

      <BookSection />
      <Footer />
    </>
  )
}
