import React, { useEffect } from 'react';

const ChatComponent = () => {
  useEffect(() => {
    // Load Tawk.to script after component mounts
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://embed.tawk.to/630d0d9437898912e9660784/1gblfq3bg';
    script.charset = 'UTF-8';
    script.setAttribute('crossorigin', '*');
    document.head.appendChild(script);

    // Cleanup script on component unmount
    return () => {
      document.head.removeChild(script);
    };
  }, []); // Empty dependency array ensures useEffect runs only once

  return (
    <div>
      {/* Your other component content */}
    </div>
  );
};

export default ChatComponent;
