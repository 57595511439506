import React from 'react';
import './GreyCard.css';
import { Grid, Container } from '@mui/material';
import FreebookIcon from '../imagesmy/freebook.svg';
import Sheild from '../imagesmy/shield.svg';
import ExpertIcon from '../imagesmy/expert.svg';
import GreyCard from './GreyCard';

const GreyCardData = [
  {
    icon: FreebookIcon,
    title: 'Effortless Vacation Reservations',
    cardText: 'Bliss Flights, the largest independent travel agent in the UK, ensures hassle-free booking. Whether a brief UK escape or a sunlit package holiday, our team assists in securing your ideal vacation. Trust Bliss Flights for a streamlined, stress-free booking process.',
  },
  {
    icon: Sheild,
    title: 'Reliable UK Travel Assistance',
    cardText: 'Welcome to Bliss Flights, the UKs largest travel agent. Planning a UK break, sun-filled package holiday, or an all-inclusive getaway? Our team ensures a smooth booking process for the holiday of your dreams. with stress-free booking process. unforgettable experiences',
  },
  {
    icon: ExpertIcon,
    title: 'Guidance from Professionals',
    cardText: 'Bliss Flights, your expert companion, offers reliable insights for your journey. Whether a short getaway or an extended vacation, trust us for expert travel advice tailored to your unique preferences. Make informed decisions and create unforgettable experiences.',
  },
];


const GreyCardSection = () => {
  return (
    <section className='py-60'>
      <h2 className='text-center'>Book your Holiday with Complete Confidence</h2>
      <Container maxWidth="lg">
        <Grid container justifyContent="center" spacing={3}>
          {GreyCardData.map((card, index) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <GreyCard
                icon={card.icon}
                title={card.title}
                cardText={card.cardText}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </section>
  );
}

export default GreyCardSection;
