import React from 'react';
import { Container } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SliderCard from './SliderCard'; // Import the custom card component
import './Whybook.css';
import pricematchImage from '../imagesmy/pricematch.svg';
import './Whybook.css';
import nofeeImage from '../imagesmy/nofee.svg';
import dealsImage from '../imagesmy/deals.svg';
import processing from '../imagesmy/processing.png';

const slideData = [
  {
    title: 'We are giving you Price Match Guarantee',
    buttonLabel: 'Book Now',
    imageUrl: pricematchImage,

    background: 'linear-gradient(0deg, #FFD8D8 0%, #FFEBEB 100%)', // Gradient background
  },
  {
    title: 'No Fee on Credit & Debit Card Transactions',
    buttonLabel: 'Book Now',
    imageUrl: nofeeImage,
    background: 'linear-gradient(1deg, #CFD4FF 2.99%, #EAECFF 98.16%)',
  },
  {
    title: 'Exclusive Deals for Hundreds of Destinations',
    buttonLabel: 'Book Now',
    imageUrl: dealsImage,
    background: 'linear-gradient(180deg, #F4EEE8 0%, #E5D7CB 100%)',
  },
  {
    title: '24/7 Active service',
    buttonLabel: 'Book Now',
    imageUrl: processing,
    background: 'lightsalmon', // Set the background color for this card
  },
  
  // Other slide data entries
];

const WhyBook = () => {
  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerPadding: '0',
    autoplay: true,  // Add autoplay property
    autoplaySpeed: 2000,  // Set autoplay speed in milliseconds
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="py-60 slider-wrapper">
    <div className='section-heading'>
        <h2>Why book with Bliss Flights?</h2>
    </div>
      <Container maxWidth="lg">
        <Slider {...sliderSettings}>
          {slideData.map((slide, index) => (
            <div key={index}>
              <SliderCard
                title={slide.title}
                buttonLabel={slide.buttonLabel}
                imageUrl={slide.imageUrl}
                background={slide.background} // Pass the background color as a prop
              />
            </div>
          ))}
        </Slider>
      </Container>
    </div>
  );
};

export default WhyBook;
