import React from 'react';
import { Container, Grid, Paper, Typography } from '@mui/material';
import blogcover from "../imagesmy/blog1cover.webp"
import BaaniSpaKagiMaldives from "../imagesmy/BaaniSpaKagiMaldives.jpg"
import VilamendhooMaldives from "../imagesmy/VilamendhooMaldives.jpg"
import SandiesBathala from "../imagesmy/SandiesBathala.jpg"
import AdaaranSelectHudhuranfushi from "../imagesmy/AdaaranSelectHudhuranfushi.png"
import KuramathiMaldives from "../imagesmy/KuramathiMaldives.jpg"
import DhigaliMaldives from "../imagesmy/DhigaliMaldives.jpg"
import KomandooIsland from "../imagesmy/KomandooIsland.jpg"
import Footer from './Footer';
import { Topnav } from './Topnav';
import Navbar from './Navbar';

const BlogDetails = () => {
    return (
        <>

            <Topnav />
            <Navbar />
            <Container maxWidth="lg" style={{ marginTop: '20px' }}>
                <Grid container spacing={3} justifyContent="center">
                    <Grid item xs={12}>
                        <Paper style={{ padding: '20px' }} elevation={3}>
                            <img src={blogcover} alt="Blog Cover" style={{ width: '100%', height: '500px ' }} />
                            <Typography variant="h4" style={{ marginTop: '20px' }}>
                                Our top reasons to visit the Maldives
                            </Typography>
                            <Typography variant="subtitle1" color="textSecondary">
                                Published on: OCt 26, 2023
                            </Typography>
                            <Typography variant="body1" style={{ marginTop: '20px' }}>
                                {/* Your blog content goes here */}
                                We've been travelling to this beautiful group of islands for almost 50 years so we thought we'd share our top reasons why you should visit the Maldives. We’ve also picked out our recommended resorts for six different types of holiday.
                            </Typography>

                            <Typography variant="h4" style={{ marginTop: '20px' }}>
                                For a romantic break or honeymoon
                            </Typography>


                            <Typography variant="body1" style={{ marginTop: '20px' }}>
                                The Maldives is a real-life desert-island fantasy and it’s our top pick for a honeymoon. Crystal clear lagoons and frosted white-sand beaches come as standard, while bespoke experiences-for-two like candlelit dinners and private sandbank picnics make it easy to ramp up the romance.
                            </Typography>

                            <Typography variant="h4" style={{ marginTop: '20px' }}>
                                Kandolhu Maldives
                            </Typography>
                            <Typography variant="body1" style={{ marginTop: '20px' }}>
                                Kandolhu Maldives is one of our most romantic resorts. This tiny picture-perfect speck in the ocean has five intimate à la carte restaurants, elegant Maldivian-style villas (many with a private pool) and a tranquil vibe. The ultimate inclusions package is incredible value for money. As well as the exquisite dining, it includes a Champagne cruise and an in-villa wine chiller stocked with over 30 international wines.
                            </Typography>


                            <Typography variant="h4" style={{ marginTop: '20px' }}>
                                Hurawhali Island Resort
                            </Typography>

                            <Typography variant="body1" style={{ marginTop: '20px' }}>
                                AHurawalhi, guests must be over the age of 15 and there are villas specifically designed for romance: Romantic Ocean Villas have an outdoor bathtub and a catamaran net where you can cosy up together above the lagoon. Plus, dreamy bucket-list experiences don’t come much bigger than dining at Hurawalhi’s remarkable all-glass underwater restaurant.                          </Typography>
                            <Typography variant="h5" style={{ marginTop: '20px' }}>
                                To go Snorkelling and Diving
                            </Typography>
                            <Typography variant="body1" style={{ marginTop: '10px' }}>
                                One of the special things about the Maldives is that most resorts are surrounded by a house reef, so you can snorkel straight from the beach alongside rainbow-colored fish, reef sharks, and turtles. Diving is a big deal here, too. There’s great visibility and at the best dive sites, you can spot astonishing creatures like manta rays and whale sharks.
                            </Typography>

                            {/* Vilamendhoo Island Resort */}
                            <Typography variant="h6" style={{ marginTop: '20px' }}>
                                Vilamendhoo Island Resort
                            </Typography>
                            <img src={VilamendhooMaldives} alt="Vilamendhoo Island Resort" style={{ width: '100%', height: 'auto' }} />
                            <Typography variant="body1" style={{ marginTop: '10px' }}>
                                Vilamendhoo is consistently voted one of the top resorts for snorkeling and diving. The colorful house reef here is fantastic, and you’ll be well-placed to explore more than 50 local dive sites. The island also works with the Manta Trust, so you can get involved with research initiatives about these gentle giants.
                            </Typography>

                            {/* Sandies Bathala */}
                            <Typography variant="h6" style={{ marginTop: '20px' }}>
                                Sandies Bathala
                            </Typography>
                            <img src={SandiesBathala} alt="Sandies Bathala" style={{ width: '100%', height: 'auto' }} />
                            <Typography variant="body1" style={{ marginTop: '10px' }}>
                                This tiny gem of an island, exclusive to Kuoni travelers in the UK, is renowned for its fantastic snorkeling and diving, having both a superb house reef and a privileged location in the marine-rich North Ari Atoll. Between underwater adventures, cool off in the infinity pool, dine on fresh seafood and sip bubbles on a sunset cruise.
                            </Typography>

                            {/* All-Inclusive Escape Section */}
                            {/* Add similar sections for Adaaran Select Hudhuranfushi and Emerald Maldives */}
                            {/* Adaaran Select Hudhuranfushi */}
                            <Typography variant="h5" style={{ marginTop: '20px' }}>
                                For an All-Inclusive Escape
                            </Typography>
                            <Typography variant="body1" style={{ marginTop: '10px' }}>
                                As a collection of single-resort islands, the Maldives is a destination where it makes sense to opt for the complete package. We have lots of recommendations for a Maldives all-inclusive holiday, but a couple of resorts offer particularly outstanding value for what’s included.
                            </Typography>

                            {/* Adaaran Select Hudhuranfushi */}
                            <Typography variant="h6" style={{ marginTop: '20px' }}>
                                Adaaran Select Hudhuranfushi
                            </Typography>
                            <img src={AdaaranSelectHudhuranfushi} alt="Adaaran Select Hudhuranfushi" style={{ width: '100%', height: 'auto' }} />
                            <Typography variant="body1" style={{ marginTop: '10px' }}>
                                We love the all-inclusive package at laid-back Adaaran Select Hudhuranfushi. Stay six nights or more and you’ll get two cruise excursions included, as well as selected non-motorized water sports. When you're ready for refreshment, prop yourself at one of the bars where you can choose from a range of well-known brand drinks. On the culinary side, there’s buffet-style Banyan, authentic curries in the Indian Pavilion, and beach grill snacks.
                            </Typography>

                            {/* Emerald Maldives */}
                            <Typography variant="h6" style={{ marginTop: '20px' }}>
                                Emerald Maldives
                            </Typography>
                            <img src={VilamendhooMaldives} alt="Emerald Maldives" style={{ width: '100%', height: 'auto' }} />
                            <Typography variant="body1" style={{ marginTop: '10px' }}>
                                A stay at cool and contemporary Emerald Maldives includes its Deluxe All-Inclusive package. You can eat at a choice of restaurants – we’re big fans of lazy lunches at toes-in-the-sand Beach Club Grill and the South American dishes cooked over hot coals at Amazònico – and your minibar comes fully stocked. You’ll also get added extras like guided snorkeling trips and excursions.
                            </Typography>

                            {/* Active Family Holiday Section */}
                            {/* Add similar sections for Kuramathi Maldives and Dhigali Maldives */}
                            {/* Kuramathi Maldives */}
                            <Typography variant="h5" style={{ marginTop: '20px' }}>
                                For an Active Family Holiday
                            </Typography>
                            <Typography variant="body1" style={{ marginTop: '10px' }}>
                                We’ve visited more than 60 islands in the Maldives and have found the resorts that are perfect for family holidays. Think action-packed children’s clubs, shallow lagoon waters, and plenty of fun activities for all the family (or just for the kids if you’d prefer to sneak off to the spa).
                            </Typography>

                            {/* Kuramathi Maldives */}
                            <Typography variant="h6" style={{ marginTop: '20px' }}>
                                Kuramathi Maldives
                            </Typography>
                            <img src={KuramathiMaldives} alt="Kuramathi Maldives" style={{ width: '100%', height: 'auto' }} />
                            <Typography variant="body1" style={{ marginTop: '10px' }}>
                                We think Kuramathi Maldives is so special that we’ve made it exclusive – this means we’re the only tour operator in the UK to offer the island to UK travelers. Kuramathi is one of the larger islands, so there’s more space for kids to run free. There are also more facilities – like a kids’ club with a great splash pool and a shallow lagoon – and 14 restaurants mean even fussy little eaters will find something they’ll tuck into. The best family rooms are the Two Bedroom Beach Houses, which sleep six and are set right on the beach.
                            </Typography>

                            {/* Dhigali Maldives */}
                            <Typography variant="h6" style={{ marginTop: '20px' }}>
                                Dhigali Maldives
                            </Typography>
                            <img src={DhigaliMaldives} alt="Dhigali Maldives" style={{ width: '100%', height: 'auto' }} />
                            <Typography variant="body1" style={{ marginTop: '10px' }}>
                                Family-friendly Dhigali Maldives offers lots of activities out on the water, with swimming in the calm lagoon, glass-bottom boat trips and dolphin cruises. The kids’ club has a splash park and organizes fun events like treasure hunts, while at Jade restaurant, kids can make their own pizzas. The resort has golf buggies to make it easier for tired little legs and there are plenty of rooms ideal for families – if your children are over 12 then you could stay in a Lagoon Villa with Pool for the iconic villa-on-stilts experience.
                            </Typography>

                            {/* Escape and Relax Section */}
                            {/* Add similar sections for Kagi Maldives Spa Island and Komandoo Island Resort & Spa */}
                            {/* Kagi Maldives Spa Island */}
                            <Typography variant="h5" style={{ marginTop: '20px' }}>
                                To Escape and Relax
                            </Typography>
                            <Typography variant="body1" style={{ marginTop: '10px' }}>
                                Escapism is effortless in the Maldives, thanks to the nature of its peaceful private islands and front-row ocean views. If you can’t chill out here, you’ll struggle to do it anywhere – but a couple of resorts go above and beyond to help.
                            </Typography>

                            {/* Kagi Maldives Spa Island */}
                            <Typography variant="h6" style={{ marginTop: '20px' }}>
                                Kagi Maldives Spa Island
                            </Typography>
                            <img src={BaaniSpaKagiMaldives} alt="Kagi Maldives Spa Island" style={{ width: '100%', height: 'auto' }} />
                            <Typography variant="body1" style={{ marginTop: '10px' }}>
                                Self-care is the order of the day at boutique Kagi Maldives Spa Island. This new island is all about well-being; expect restorative yoga sessions, nourishing meals, and soothing spa therapies. The highlight is the overwater Baani wellness retreat, which has ocean-view treatment rooms and the Spa Corner restaurant where mouth-watering dishes have been created to help recharge your body and mind.
                            </Typography>

                            {/* Komandoo Island Resort & Spa */}
                            <Typography variant="h6" style={{ marginTop: '20px' }}>
                                Komandoo Island Resort & Spa
                            </Typography>
                            <img src={KomandooIsland} alt="Komandoo Island Resort & Spa" style={{ width: '100%', height: 'auto' }} />
                            <Typography variant="body1" style={{ marginTop: '10px', marginBottom: '20px' }}>
                                Komandoo Island Resort & Spa has all the key ingredients for pure undisturbed escapism: it’s intimate, peaceful, and for adults only. Lots of guests return to this stunning natural island year after year, which creates a quiet yet friendly atmosphere. Keep things calm with gentle canoeing trips, slow-paced snorkeling, toes-in-the-sand dining, and low-key cocktails.
                            </Typography>



                        </Paper>
                    </Grid>
                </Grid>
            </Container>

            <Footer />

        </>
    );
};

export default BlogDetails;
