import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import Navbar from '../Navbar';
import { Topnav } from '../Topnav';

export default function MiddleEast() {
  return(
    <>
    <Topnav/>
    <Navbar/>
     <h1 className='desti'>Holidays</h1>;

     <Footer/>
    </>
  ) 
}
