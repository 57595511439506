import React from 'react';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import footSpcae from "../imagesmy/morefootspace.svg"

const CustomCheckbox = ({ type, onClick, checked, disabled }) => {
  const handleCheckboxChange = () => {
    // Call the appropriate click handler based on the checkbox type
    if (onClick) {
      onClick(type);
    }
  };

  const getCheckboxStyle = () => {
    switch (type) {
      case 'moreFootSpace':
        return {
          backgroundImage: '',
          backgroundSize: 'cover',
          backgroundColor: '#FC3C00',
        };
      case 'blueCheckbox':
        return {
          backgroundImage: ``,
          backgroundSize: 'cover',
          backgroundColor: '#007bff',
        };
      case 'disabledCheckbox':
        return {
          backgroundImage: ``,
          backgroundSize: 'cover',
          backgroundColor: '#ccc', // Adjust background color for disabled state
        };
      default:
        return {
          backgroundColor: '#fff',
        };
    }
  };

  return (
    <Paper
      elevation={3}
      style={{
        cursor: disabled ? 'not-allowed' : 'pointer',
        position: 'relative',
        height: '23px',
        width: '23px',
        ...getCheckboxStyle(),
      }}
      onClick={handleCheckboxChange}
    >
      <Checkbox
        checked={checked}
        onChange={handleCheckboxChange}
        disabled={disabled}
        style={{ visibility: 'hidden' }}
      />
      {checked && !disabled && (
        <CheckCircleIcon
          className="selected-icon"
          style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
        />
      )}
    </Paper>
  );
};

export default CustomCheckbox;
