import React from 'react';
import { Accordion, Container, Grid, Typography } from '@mui/material';
import './Cards.css'; // Make sure to include your CSS file
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Define a CSS class for the accordion container
const accordionContainerStyle = {
  marginBottom: '20px', // Add margin at the bottom of each accordion
  border: '1px solid #D8D8D8', // Add a border to each accordion
  borderRadius: '8px', // Optional: Add border radius for rounded corners
  boxShadow: 'none', // Remove the box shadow
};

export default function BasicAccordion() {
  return (
    <div>
      <section className='py-60'>
        <h2 className='text-center'>Frequently asked questions</h2>
        <Container maxWidth="lg">
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={6}>
            <Accordion style={accordionContainerStyle}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6">What is an e-ticket?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                  
E-tickets, which have been adopted by the majority of our suppliers, allow passengers ticketless travel. If the airline you are travelling with is uses e-tickets, you will be sent the required information via email within 24 hours of making your booking. Once received, simply print the documentation, as it is required for you to travel.

                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion style={accordionContainerStyle}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6"> What does API mean and who do I give this information to?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Advance Passenger Information (API) must be provided to the authorities before you travel. This required information includes your passport details and your contact information. Incorrect or incomplete information may result in your journey being delayed, either at check in or immigration. Bliss Flights Ltd cannot accept responsibility for incomplete or inaccurate information and no refunds will be issued if you are unable to travel as a consequence of this. If you are unsure on who you provide your API to then please contact the telephone number or email address provided on your Bliss Flights  Booking Confirmation.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion style={accordionContainerStyle}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6"> When will I receive my tickets?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    The majority of our suppliers now issue e-tickets. Information relating to your booking will be sent to you within 48 hours of the booking being made. There are, however, some exceptions. Some low cost airlines now require boarding cards to be printed before check-in.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion style={accordionContainerStyle}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6"> Are flight meals included?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Low cost airlines do not offer this facility, however, many will offer a pay-on-board trolley service during your flight. If you are traveling to a long-haul destination and wish to add this service at an additional cost then please contact the telephone number or email address provided on your Bliss Flights Booking Confirmation.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion style={accordionContainerStyle}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6"> How long will it take for my booking confirmation to come through?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Where an email address has been provided, a confirmation email containing your booking summary will be sent to you within 48 hours.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion style={accordionContainerStyle}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6">

                    Can I change my  baggage allowance once I have booked?

                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    The majority of airlines will allow you to increase the amount of luggage you wish to take for an additional fee. This will vary for each individual airline. If you would like to upgrade your hold luggage after booking, please contact the telephone number or email address provided on your Bliss Flights Booking Confirmation. If you do exceed your pre-arranged luggage allowance, the airline is within its rights to charge any excess baggage fees.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion style={accordionContainerStyle}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6">

                    Can I take liquids on-board the aircraft?

                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>

                    There are restrictions on what items you are permitted to carry in your hand luggage and hold luggage when boarding aircraft in the UK.
                    <b>Taking liquids through security</b>
                    <p>There are restrictions on the amount of liquids you can carry in your hand luggage. If possible, pack liquids in your hold luggage (this is the baggage that you check-in prior to your flight).</p>
                    <b>If you do take liquids in your hand luggage then:</b>
                    <ul>
                      <li>Liquids must be placed in containers that hold no more than 100ml each. Containers should be placed in a single, transparent, resealable plastic bag measuring approximately 20cm x 20cm. No more than one litre of liquids are to be carried in the plastic bag. The contents must fit comfortably inside the plastic bag so it can be sealed. The plastic bag must not be knotted or tied at the top. Only one plastic bag is to be carried per person and it must be shown at the airport security point prior to boarding your flight.</li>
                      <li>Containers larger than 100ml (except essential medicines), will not be allowed through the security point, even if they are only part full.</li>
                      <b>Liquids include:</b>
                      <p>All drinks, including water, soup and syrups Cosmetics and toiletries, including creams, lotions, oils, perfumes, mascara and lipsticks
                        Sprays, including shaving foam, hairspray and spray deodorants
                        Pastes, including toothpaste
                        Gels, including hair and shower gel
                        Contact lens solution
                        Any other solutions and items of similar consistency
                      </p>
                    </ul>
<b>Cigarette Lighters</b>
<p>You can only carry one cigarette lighter onboard the aircraft and it should be put inside a plastic bag with any liquids you may be carrying. You must keep the cigarette lighter with you throughout the flight. Please note that cigarette lighters cannot be placed inside hold luggage. Download the Civil Aviation Authority's (CAA) guide for full details of what you can carry in your luggage.
Further information can be found on the Direct Gov website, however, Bliss Flights  is not responsible, and accepts no liability, for the content found on the Direct Gov website.
</p>

                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion style={accordionContainerStyle}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header" >
                  <Typography variant="h6">Can I take medicine abroad?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                  If you require prescribed medication for a health condition, you should talk to your GP or Practice Nurse about travelling before your holiday, as they will be able tell you if you need to make any special arrangements. You should also always carry your medication in a correctly labelled container as issued by your pharmacist. Try to carry your medication in your hand luggage (airline regulations permitting), with a copy of your prescription. Try and also pack a spare supply of medication in your suitcase or hold luggage where possible, in case you lose your hand luggage. It may be a good idea to travel with a copy of your prescription. Some airlines require a letter from your GP giving details of your medication, as well as the name of the health condition that you require the medication for.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Container>
      </section>
    </div>
  );
}
