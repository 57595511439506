// ReviewCards.js
import React from 'react';
import './ReviewCards.css';
import qoutes from '../imagesmy/qoutes.svg'

const ReviewCards = ({ userprofile, name, reviewText, Rating, stars }) => {
  return (
    <div className='Review-card'>
     <div className='card-header'>
      <div className='d-flex'>
       <img src={userprofile} alt="" />
        <span className='name'>{name}</span>
      </div>
      <img className='qouts' src={qoutes} alt="" />
     </div>

      <p>{reviewText}</p>
      <span>{Rating}</span>
     <img className='stars' src={stars} alt="" />
    </div>
  );
};

export default ReviewCards;
