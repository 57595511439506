import React from 'react';
import './GreyCard.css';

const GreyCard = ({ title, icon, cardText }) => {
  return (
    <>
    
    <div className='grey-card-wrapper'>
      <img src={icon} alt={title} />
      <h4>{title}</h4>
      <p>{cardText}</p>
    </div>
    
    </>
  );
};

export default GreyCard;
