import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import Navbar from '../Navbar';

export default function Asia() {
  return (
    <>
    <Navbar/>
     <h1 className='desti'>Middle East</h1>;

     <Footer/>
    </>
  ) 
}
